export enum EventType {
	TAP_IN = 'tapIn',
	APP_IN = 'appIn',
	CHECKIN = 'checkin',
	SCAN = 'scan',
	ENTERED = 'customerEntered',
	CHECKOUT_ZONE_ENTERED = 'checkoutZoneEntered',
	CHECKOUT_ZONE_LEFT = 'checkoutZoneLeft',
	LEFT = 'customerLeft',
	CHECKOUT = 'checkout',
}

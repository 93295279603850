import { CustomerJourneyState } from './DashboardJourney';

export default interface AccuracyTestingOverviewDTO {
	startDate: Date;
	endDate: Date;
	totalJourneys: number;
	journeysWithErrors: number;
	totalItems: number;
	itemsWithErrors: number;
	testingJourneys: Array<AccuracyTestingJourneyDashboardListDTO>;
}

export interface AccuracyTestingJourneyDashboardListDTO {
	customerId: number;
	reviewed: boolean;
	itemCount: number;
	errorCount: number;
	groupSize: number;
	timestamp: Date;
}

export interface AccuracyTestingJourneyInterface {
	customerId: number;
	aifiCustomerId: number;
	aifiSessionId: string;
	aifiOrderId: string | null;
	entryCode: string;
	aifiState: CustomerJourneyState;
	aifiItems: Array<CartItemWithProductCode>;
	aifiRestrictedItemsDetected: boolean;
	scanState: AccuracyTestingScanState;
	scannedItems: Array<CartItemWithProductCode>;
	scanRestrictedItemsDetected: boolean;
	entryGateId: string;
	referenceOrderCustomerId: number;
	maxCustomerAmount: number;
	reviewed: boolean;
	manualReviews: Array<AccuracyTestingManualReview>;
	comment: string | null;
	itemCount: number;
	errorCount: number;
}

export enum AccuracyTestingScanState {
	PENDING = 'pending',
	COMPLETED = 'completed',
}

export interface CartItemWithProductCode {
	code: string;
	amount: number;
	name: string | null;
	productId: string | null;
	productCode: string;
	overrideUniqueProductIdentifier: string | null;
}

export enum AccuracyTestingManualReviewType {
	MASTER_DATA_CONFLICT = 'MASTER_DATA_CONFLICT',
	MISIDENTIFIED_ITEM = 'MISIDENTIFIED_ITEM',
}

export interface AccuracyTestingManualReview {
	type: AccuracyTestingManualReviewType;
	aifiProductIdentifier: string;
	scanProductIdentifier: string;
}

import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useAppDispatch } from '../datalayer/hooks';
import { setRoles, setSignedIn, setTokenExpiryDate } from '../datalayer/Actions';
import { useLocation, useNavigate } from 'react-router-dom';
import API from '../datalayer/API';

const theme = createTheme();

export default function SignInPage(props: any) {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const { state } = useLocation();

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		const email = String(data.get('email'));
		const password = String(data.get('password'));

		try {
			const response = await API.login(email, password);
			if (window.location.hostname.includes('localhost')) {
				localStorage.setItem('dev-access-token', response.accessToken);
			}
			dispatch(setSignedIn(true));
			dispatch(setTokenExpiryDate(response.expiresAt.toISOString()));
			dispatch(setRoles(JSON.parse(window.atob(response.accessToken.split('.')[1])).roles));
			navigate(state?.from || '/');
		} catch (e) {
			console.error(e);
			alert("Couldn't sign in. Please try again.");
		}
	};

	return (
		<ThemeProvider theme={theme}>
			<Container component='main' maxWidth='xs'>
				<CssBaseline />
				<Box
					sx={{
						marginTop: 8,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
						<LockOutlinedIcon />
					</Avatar>
					<Typography component='h1' variant='h5'>
						Sign in
					</Typography>
					<Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
						<TextField margin='normal' required fullWidth id='email' label='Email Address' name='email' autoComplete='email' autoFocus />
						<TextField
							margin='normal'
							required
							fullWidth
							name='password'
							label='Password'
							type='password'
							id='password'
							autoComplete='current-password'
						/>
						<Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
							Sign In
						</Button>
					</Box>
				</Box>
			</Container>
		</ThemeProvider>
	);
}

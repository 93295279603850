import GateScanner from '../models/GateScanner';
import GateScreenState from '../models/GateScreenState';
import ReduxAction from './ReduxAction';
import {
	DELETE_STORE,
	SET_CURRENT_CUSTOMER_AMOUNT,
	SET_GATE_SCANNERS,
	SET_GATE_SCREEN_STATES,
	SET_MAX_CUSTOMER_AMOUNT,
	SET_ROLES,
	SET_SIGNED_IN,
	SET_SSE_ALLOWED,
	SET_SSE_CONNECTED,
	SET_TOKEN_EXPIRY_DATE,
	UPDATE_GATE_SCREEN_STATES,
} from './Types';

export const deleteStore = () => ReduxAction(DELETE_STORE, null);

export const setSignedIn = (isSignedIn: boolean) => ReduxAction(SET_SIGNED_IN, isSignedIn);

export const setTokenExpiryDate = (expiryDate: string) => ReduxAction(SET_TOKEN_EXPIRY_DATE, expiryDate);

export const setRoles = (roles: Array<string>) => ReduxAction(SET_ROLES, roles);

export const setGateScanners = (gateScanners: Array<GateScanner>) => ReduxAction(SET_GATE_SCANNERS, gateScanners);

export const updateGateScreenStates = (gateScreenStates: Array<GateScreenState>) => ReduxAction(UPDATE_GATE_SCREEN_STATES, gateScreenStates);

export const setGateScreenStates = (gateScreenStates: Array<GateScreenState>) => ReduxAction(SET_GATE_SCREEN_STATES, gateScreenStates);

export const setCurrentCustomerAmount = (currentCustomerAmount: number) => ReduxAction(SET_CURRENT_CUSTOMER_AMOUNT, currentCustomerAmount);

export const setMaxCustomerAmount = (maxCustomerAmount: number | null) => ReduxAction(SET_MAX_CUSTOMER_AMOUNT, maxCustomerAmount);

export const setSseConnected = (isSseConnected: boolean) => ReduxAction(SET_SSE_CONNECTED, isSseConnected);

export const setSseAllowed = (isSseAllowed: boolean) => ReduxAction(SET_SSE_ALLOWED, isSseAllowed);

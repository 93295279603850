import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Alert, Box, Button, Paper, Typography } from '@mui/material';
import { Contactless, Group, Storage, Videocam } from '@mui/icons-material';
import { CustomerJourneyState, JourneyType } from '../../models/DashboardJourney';
import API from '../../datalayer/API';
import { BopState, PaymentState } from '../../models/DashboardOrder';
import TitleLive from '../General/TitleLive';
import { DateTime } from 'luxon';
import { EventType } from '../../models/EventType';
import { RoleGate } from '../General/RoleGate';
import { StaffRole } from '../../models/StaffRole';

const ReceiptCounts = () => {
	const [lastRefresh, setLastRefresh] = useState(new Date());

	const [leftJourneys, setLeftJourneys] = useState(0);
	const [lostJourneys, setLostJourneys] = useState(0);
	const [blockedAiFiChekoutEvents, setBlockedAiFiChekoutEvents] = useState(0);
	const [pricingPendingAmount, setPricingPendingAmount] = useState(0);
	const [directCheckoutPendingAmount, setDirectCheckoutPendingAmount] = useState(0);
	const [bopPendingAmount, setBopPendingAmount] = useState(0);
	const [completedAppJourneys, setCompletedAppJourneys] = useState(0);
	const [completedTapInJourneys, setCompletedTapInJourneys] = useState(0);

	const [checkoutBlocked, setCheckoutBlocked] = useState(false);

	useEffect(() => {
		refreshAll();
	}, []);

	const refreshAll = () => {
		refreshLeftJourneys();
		refreshLostJourneys();
		refreshBlockedAiFiChekoutEvents();
		refreshPricingPendingAmount();
		refreshDirectCheckoutPendingAmount();
		refreshBopPendingAmount();
		refreshCompletedAppJourneys();
		refreshCompletedTapInJourneys();
		refreshCheckoutBlocked();
		setLastRefresh(new Date());
	};

	const refreshLeftJourneys = async () => {
		setLeftJourneys(
			await API.getJourneyAmount({
				state: CustomerJourneyState.LEFT,
			})
		);
	};

	const refreshLostJourneys = async () => {
		const endDate = new Date(Date.now() - 2 * 60 * 60 * 1000);
		setLostJourneys(await API.getJourneyAmount({ state: CustomerJourneyState.CHECKIN, endDate }));
	};

	const refreshBlockedAiFiChekoutEvents = async () => {
		setBlockedAiFiChekoutEvents(
			await API.getFailedEventsAmount({
				type: EventType.CHECKOUT,
			})
		);
	};

	const refreshPricingPendingAmount = async () => {
		setPricingPendingAmount(await API.getOrderAmount({ paymentState: PaymentState.PRICING_PENDING }));
	};

	const refreshDirectCheckoutPendingAmount = async () => {
		setDirectCheckoutPendingAmount(await API.getOrderAmount({ paymentState: PaymentState.DIRECT_CHECKOUT_PENDING }));
	};

	const refreshBopPendingAmount = async () => {
		setBopPendingAmount(await API.getOrderAmount({ bopState: BopState.PENDING }));
	};

	const refreshCompletedAppJourneys = async () => {
		const startDate = new Date();
		startDate.setHours(0, 0, 0, 0);
		setCompletedAppJourneys(await API.getJourneyAmount({ state: CustomerJourneyState.COMPLETED, type: JourneyType.APP_IN, startDate }));
	};

	const refreshCompletedTapInJourneys = async () => {
		const startDate = new Date();
		startDate.setHours(0, 0, 0, 0);
		setCompletedTapInJourneys(await API.getJourneyAmount({ state: CustomerJourneyState.COMPLETED, type: JourneyType.TAP_IN, startDate }));
	};

	const refreshCheckoutBlocked = async () => {
		setCheckoutBlocked(await API.isCheckoutBlocked());
	};

	const lostInStoreDate = new Date(Date.now() - 2 * 60 * 60 * 1000);

	const sixAmInLondonTimeZone = DateTime.now().setZone('Europe/London').startOf('day').plus({ hours: 6 }).toJSDate();

	return (
		<Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
			<TitleLive sseRelevant={false} refresh={refreshAll} lastRefresh={lastRefresh}>
				Journey Overview
			</TitleLive>
			{checkoutBlocked ? (
				<Alert severity='warning' sx={{ mb: 2 }}>
					Checkout event processing is disabled by ALDI
				</Alert>
			) : null}
			<Grid container spacing={3}>
				<Grid item xs={12} md={12} lg={12} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start', mt: 'auto' }}>
					<Videocam sx={{ fontSize: 30, mb: 'auto' }} color='action' />
					<Box sx={{ ml: 2, flex: 1 }}>
						<Typography color='text.primary' variant='h6'>
							AiFi Pending - {leftJourneys + lostJourneys}
						</Typography>
						<Box sx={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
							<Typography color='text.secondary' sx={{ mt: 'auto', mb: 'auto', mr: 1, fontWeight: 'bold' }}>
								{leftJourneys}
							</Typography>
							<Typography color='text.secondary' sx={{ mt: 'auto', mb: 'auto' }}>
								pending
							</Typography>
							<Button size='medium' href={'journeys?state=left'} sx={{ mt: 'auto', mb: 'auto', ml: 'auto' }}>
								Show Journeys
							</Button>
						</Box>
						<Box sx={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
							<Typography color='text.secondary' sx={{ mt: 'auto', mb: 'auto', fontWeight: 'bold', mr: 1 }}>
								{lostJourneys}
							</Typography>
							<Typography color='text.secondary' sx={{ mt: 'auto', mb: 'auto' }}>
								lost in store
							</Typography>
							<Button
								size='medium'
								href={`journeys?endDate=${lostInStoreDate.toISOString()}&state=checkin`}
								sx={{ mt: 'auto', mb: 'auto', ml: 'auto' }}
							>
								Show Journeys
							</Button>
						</Box>
						{checkoutBlocked || blockedAiFiChekoutEvents > 0 ? (
							<Box sx={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
								<Typography color='text.secondary' sx={{ mt: 'auto', mb: 'auto', fontWeight: 'bold', mr: 1 }}>
									{blockedAiFiChekoutEvents}
								</Typography>
								<Typography color='text.secondary' sx={{ mt: 'auto', mb: 'auto' }}>
									{checkoutBlocked ? 'of those blocked by ALDI' : 'not processed by ALDI'}
								</Typography>
								<RoleGate requiredRole={StaffRole.ROOT}>
									<Button size='medium' href={`system-health`} sx={{ mt: 'auto', mb: 'auto', ml: 'auto' }}>
										Settings
									</Button>
								</RoleGate>
							</Box>
						) : null}
					</Box>
				</Grid>
				<Grid item xs={12} md={12} lg={12} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start', mt: 'auto' }}>
					<Storage sx={{ fontSize: 30, mb: 'auto' }} color='action' />
					<Box sx={{ ml: 2, flex: 1 }}>
						<Typography color='text.primary' variant='h6'>
							ALDI Pending - {pricingPendingAmount + directCheckoutPendingAmount} (
							{pricingPendingAmount + directCheckoutPendingAmount + (bopPendingAmount - pricingPendingAmount - directCheckoutPendingAmount)})
						</Typography>
						<Box sx={{ display: 'flex', flexDirection: 'row' }}>
							<Typography color='text.secondary' sx={{ mt: 'auto', mb: 'auto', mr: 1, fontWeight: 'bold' }}>
								{pricingPendingAmount}
							</Typography>
							<Typography color='text.secondary' sx={{ mt: 'auto', mb: 'auto' }}>
								waiting for pricing
							</Typography>
							<Button size='medium' href={'orders?paymentState=PRICING_PENDING'} sx={{ mt: 'auto', mb: 'auto', ml: 'auto' }}>
								Show Orders
							</Button>
						</Box>
						<Box sx={{ display: 'flex', flexDirection: 'row' }}>
							<Typography color='text.secondary' sx={{ mt: 'auto', mb: 'auto', mr: 1, fontWeight: 'bold' }}>
								{directCheckoutPendingAmount}
							</Typography>
							<Typography color='text.secondary' sx={{ mt: 'auto', mb: 'auto' }}>
								could not be sent to Spryker
							</Typography>
							<Button size='medium' href={'orders?paymentState=DIRECT_CHECKOUT_PENDING'} sx={{ mt: 'auto', mb: 'auto', ml: 'auto' }}>
								Show Orders
							</Button>
						</Box>
						<Box sx={{ display: 'flex', flexDirection: 'row' }}>
							<Typography color='text.secondary' sx={{ mt: 'auto', mb: 'auto', mr: 1, fontWeight: 'bold' }}>
								{bopPendingAmount - pricingPendingAmount}
							</Typography>
							<Typography color='text.secondary' sx={{ mt: 'auto', mb: 'auto' }}>
								could not be sent to Store 2014
							</Typography>
							<Button size='medium' href={'orders?bopState=PENDING'} sx={{ mt: 'auto', mb: 'auto', ml: 'auto' }}>
								Show Orders
							</Button>
						</Box>
					</Box>
				</Grid>
				<Grid item xs={12} md={12} lg={12} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start', mt: 'auto' }}>
					<Group sx={{ fontSize: 30, mb: 'auto' }} color='action' />
					<Box sx={{ ml: 2, flex: 1 }}>
						<Typography color='text.primary' variant='h6'>
							Completed Journeys Today - {completedAppJourneys + completedTapInJourneys}
						</Typography>
						<Box sx={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
							<Typography color='text.secondary' sx={{ mt: 'auto', mb: 'auto', mr: 1, fontWeight: 'bold' }}>
								{completedAppJourneys}
							</Typography>
							<Typography color='text.secondary' sx={{ mt: 'auto', mb: 'auto' }}>
								App Journeys{' '}
								{completedAppJourneys + completedTapInJourneys > 0
									? `(${Math.round((completedAppJourneys / (completedAppJourneys + completedTapInJourneys)) * 100)}%)`
									: ''}
							</Typography>
							<Button
								size='medium'
								href={`journeys?startDate=${sixAmInLondonTimeZone.toISOString()}&state=completed&type=APP_IN`}
								sx={{ mt: 'auto', mb: 'auto', ml: 'auto' }}
							>
								Show Journeys
							</Button>
						</Box>
						<Box sx={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
							<Typography color='text.secondary' sx={{ mt: 'auto', mb: 'auto', fontWeight: 'bold', mr: 1 }}>
								{completedTapInJourneys}
							</Typography>
							<Typography color='text.secondary' sx={{ mt: 'auto', mb: 'auto' }}>
								Tap In Journeys{' '}
								{completedAppJourneys + completedTapInJourneys > 0
									? `(${Math.round((completedTapInJourneys / (completedAppJourneys + completedTapInJourneys)) * 100)}%)`
									: ''}
							</Typography>
							<Button
								size='medium'
								href={`journeys?startDate=${sixAmInLondonTimeZone.toISOString()}&state=completed&type=TAP_IN`}
								sx={{ mt: 'auto', mb: 'auto', ml: 'auto' }}
							>
								Show Journeys
							</Button>
						</Box>
					</Box>
				</Grid>
			</Grid>
		</Paper>
	);
};

export default ReceiptCounts;

import { Box, Button, Divider, Paper, TextField, Typography } from '@mui/material';
import { Add, Delete, Edit, Save } from '@mui/icons-material';
import ManualChapter from '../../models/ManualChapter';
import { RoleGate } from '../General/RoleGate';
import { StaffRole } from '../../models/StaffRole';
import ManualSection from '../../models/ManualSection';
import { useState } from 'react';
import API from '../../datalayer/API';

const Chapter = ({ chapter, reload }: { chapter: ManualChapter; reload: () => Promise<void> }) => {
	const [editing, setEditing] = useState(false);

	const [editedChapterName, setEditedChapterName] = useState(chapter.title);
	const [editedChapterSections, setEditedChapterSections] = useState(chapter.sections);

	const updateChapter = async () => {
		await API.updateManualChapter({ ...chapter, title: editedChapterName, sections: editedChapterSections, lastUpdated: new Date() });
		setEditing(false);
		await reload();
	};

	const updateSection = (index: number, section: ManualSection) => {
		const newSections = [...editedChapterSections];
		newSections[index] = section;
		setEditedChapterSections(newSections);
	};

	const addSection = () => {
		const newSections = [...editedChapterSections];
		newSections.unshift(new ManualSection('', ''));
		setEditedChapterSections(newSections);
	};

	const deleteSection = (index: number) => {
		const newSections = [...editedChapterSections];
		newSections.splice(index, 1);
		setEditedChapterSections(newSections);
	};

	return (
		<Paper
			key={chapter.id}
			sx={{
				display: 'flex',
				flexDirection: 'column',
				mb: 4,
			}}
		>
			<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, pl: 2, pr: 2, pb: 2 }}>
				{editing ? (
					<TextField
						value={editedChapterName}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							setEditedChapterName(event.target.value);
						}}
						id='title'
						label='Chapter Title'
						variant='outlined'
					/>
				) : (
					<Typography variant='h6'>{chapter.title}</Typography>
				)}
				<RoleGate requiredRole={StaffRole.ROOT}>
					{editing ? (
						<>
							<Button
								sx={{ ml: 'auto' }}
								variant='outlined'
								color='primary'
								startIcon={<Add />}
								onClick={() => {
									addSection();
								}}
							>
								Add Section
							</Button>
							<Button
								sx={{ ml: 2 }}
								variant='outlined'
								color='primary'
								startIcon={<Save />}
								onClick={() => {
									updateChapter();
								}}
							>
								Save Chapter
							</Button>
						</>
					) : (
						<Button
							sx={{ ml: 'auto' }}
							variant='outlined'
							color='primary'
							startIcon={<Edit />}
							onClick={() => {
								setEditing(true);
							}}
						>
							Edit Chapter
						</Button>
					)}
				</RoleGate>
			</Box>
			{editedChapterSections.map((section, index) => (
				<Section section={section} index={index} editing={editing} updateSection={updateSection} deleteSection={deleteSection} />
			))}
		</Paper>
	);
};

const Section = ({
	section,
	editing,
	index,
	updateSection,
	deleteSection,
}: {
	section: ManualSection;
	editing: boolean;
	index: number;
	updateSection: (index: number, section: ManualSection) => void;
	deleteSection: (index: number) => void;
}) => {
	if (editing) {
		return (
			<>
				<Divider sx={{ mb: 2 }} />
				<Box sx={{ display: 'flex', flexDirection: 'column', pl: 2, pr: 2, pb: 2 }}>
					<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>
						<TextField
							value={section.title}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
								section.title = event.target.value;
								updateSection(index, section);
							}}
							id='title'
							label='Section Title'
							variant='outlined'
							sx={{ pb: 2, flex: 1 }}
						/>
						<Button
							sx={{ pl: 2, mb: 2 }}
							variant='text'
							color='primary'
							startIcon={<Delete />}
							onClick={() => {
								deleteSection(index);
							}}
						></Button>
					</Box>
					<TextField
						value={section.content}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							section.content = event.target.value;
							updateSection(index, section);
						}}
						id='content'
						label='Section Content'
						variant='outlined'
						multiline
						minRows={3}
					/>
				</Box>
			</>
		);
	}
	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', pl: 2, pr: 2, pb: 2 }}>
			<Typography variant='subtitle1' sx={{ fontWeight: 'bold' }}>
				{section.title}
			</Typography>
			<Typography variant='body2'>
				{section.content.split('\n').map((line) => (
					<span>
						{line}
						<br />
					</span>
				))}
			</Typography>
		</Box>
	);
};

export default Chapter;

import * as React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {
	ErrorOutline,
	DoorSlidingOutlined,
	CropFree,
	Person,
	ShoppingCart,
	CurrencyPound,
	Contactless,
	CenterFocusWeak,
	PriorityHigh,
} from '@mui/icons-material';
import { Color } from '../../assets/Color';
import Box from '@mui/material/Box';
import { Paper } from '@mui/material';
import TapAppInEvent from '../../models/TapAppInEvent';
import { TapAppInEventResult } from '../../models/TapAppInEventPayload';
import { EventType } from '../../models/EventType';

const TapAppInEventItem: React.FC<{ event: TapAppInEvent; displayButton: boolean; displayFullDate: boolean }> = ({ event, displayButton, displayFullDate }) => {
	let link = '';
	const success = event.body.result === TapAppInEventResult.OK || event.body.result === TapAppInEventResult.GROUP_ENTRY_OK;
	if (success) {
		link = `/journey?customerId=${(event as TapAppInEvent).body.customerId}`;
	} else {
		link += `/customer?sub=${(event as TapAppInEvent).body.sub}`;
	}

	const gateIdParts = event.body.gateId?.replace(/([A-Z])/g, ' $1').replace(/([0-9])/g, ' $1') ?? 'Unknown';
	const gateIdString = gateIdParts.charAt(0).toUpperCase() + gateIdParts.slice(1);

	const sub = JSON.parse(event.body.sub);

	let title = '';
	if (event.type === EventType.APP_IN && event.body.result === TapAppInEventResult.OK) {
		title = 'APP SCAN';
	} else if (event.type === EventType.APP_IN && event.body.result === TapAppInEventResult.GROUP_ENTRY_OK) {
		title = 'GROUP APP SCAN';
	} else if (event.type === EventType.APP_IN && event.body.result !== TapAppInEventResult.OK && event.body.result !== TapAppInEventResult.GROUP_ENTRY_OK) {
		title = 'APP SCAN FAILED';
	} else if (event.type === EventType.TAP_IN && event.body.result === TapAppInEventResult.OK) {
		title = 'TAP IN';
	} else if (event.type === EventType.TAP_IN && event.body.result === TapAppInEventResult.GROUP_ENTRY_OK) {
		title = 'GROUP TAP IN';
	} else if (event.type === EventType.TAP_IN && event.body.result === TapAppInEventResult.SUPPORT) {
		title = 'SUPPORT TERMINAL TAPPED';
	} else if (
		event.type === EventType.TAP_IN &&
		event.body.result !== TapAppInEventResult.OK &&
		event.body.result !== TapAppInEventResult.GROUP_ENTRY_OK &&
		event.body.result !== TapAppInEventResult.SUPPORT
	) {
		title = 'TAP IN FAILED';
	}

	return (
		<Paper elevation={3} sx={{ display: 'flex', mt: 2, p: 2 }}>
			<Box sx={{ display: 'grid', mt: 'auto', mb: 'auto', flexShrink: 1 }}>
				{event.type === EventType.APP_IN ? (
					success ? (
						<CenterFocusWeak sx={{ fontSize: 30, color: Color.BLUE, mr: 2, mt: 'auto', mb: 'auto', flexShrink: 1 }} color='primary' />
					) : (
						<Box sx={{ display: 'grid', mt: 'auto', mb: 'auto', flexShrink: 1 }}>
							<CropFree sx={{ fontSize: 30, color: Color.RED, mr: 2, gridColumn: 1, gridRow: 1 }} color='primary' />
							<ErrorOutline
								sx={{ fontSize: 15, color: Color.RED, mr: 2, gridColumn: 1, gridRow: 1, marginLeft: '0.5rem', marginTop: '0.5rem' }}
							/>
						</Box>
					)
				) : (
					<Contactless
						sx={{
							fontSize: 30,
							color: success || event.body.result === TapAppInEventResult.SUPPORT ? Color.BLUE : Color.RED,
							mr: 2,
							gridColumn: 1,
							gridRow: 1,
						}}
						color='primary'
					/>
				)}
			</Box>
			<Box sx={{ display: 'flex', flexDirection: 'column', mr: 1, mt: 'auto', mb: 'auto', flexShrink: 1 }}>
				<Typography sx={{ fontWeight: 'bold', fontSize: 14 }}>{title}</Typography>
				<Typography sx={{ color: Color.GRAY, fontSize: 13 }}>
					{displayFullDate ? new Date(event.timestamp).toLocaleString() : new Date(event.timestamp).toLocaleTimeString()}
				</Typography>
			</Box>
			{!success && event.body.result !== TapAppInEventResult.SUPPORT ? (
				<Box sx={{ display: 'flex', flexDirection: 'row', ml: 'auto', mr: 1, mt: 'auto', mb: 'auto', flexShrink: 999999 }}>
					<ErrorOutline sx={{ fontSize: 13, color: Color.RED, mr: 1, mt: 'auto', mb: 'auto' }} />
					<Typography sx={{ color: Color.GRAY, fontSize: 13, fontWeight: 'bold' }}>{event.body.result}</Typography>
				</Box>
			) : null}
			<Box sx={{ display: 'flex', flexDirection: 'column', ml: 'auto', mr: 1, mt: 'auto', mb: 'auto', flexShrink: 1 }}>
				<Box sx={{ display: 'flex', flexDirection: 'row' }}>
					<Person sx={{ fontSize: 13, color: Color.BLUE, mr: 1, mt: 'auto', mb: 'auto' }} />
					<Typography sx={{ color: Color.GRAY, fontSize: 13 }}>{sub.customer_reference}</Typography>
				</Box>
				{event.body.customerId != null && (
					<Box sx={{ display: 'flex', flexDirection: 'row' }}>
						<ShoppingCart sx={{ fontSize: 13, color: Color.BLUE, mr: 1, mt: 'auto', mb: 'auto' }} />
						<Typography sx={{ color: Color.GRAY, fontSize: 13 }}>{event.body.customerId}</Typography>
					</Box>
				)}
			</Box>
			<Box sx={{ display: 'flex', flexDirection: 'column', ml: 'auto', mr: 1, mt: 'auto', mb: 'auto', flexShrink: 1 }}>
				{event.body.result !== TapAppInEventResult.SUPPORT && (
					<Box sx={{ display: 'flex', flexDirection: 'row' }}>
						<DoorSlidingOutlined sx={{ fontSize: 13, color: Color.BLUE, mr: 1, mt: 'auto', mb: 'auto' }} />
						<Typography sx={{ color: Color.GRAY, fontSize: 13 }}>{gateIdString}</Typography>
					</Box>
				)}
				{event.body.preAuthAmount != null && (
					<Box sx={{ display: 'flex', flexDirection: 'row' }}>
						<CurrencyPound sx={{ fontSize: 13, color: Color.BLUE, mr: 1, mt: 'auto', mb: 'auto' }} />
						<Typography sx={{ color: Color.GRAY, fontSize: 13 }}>
							{(event.body.preAuthAmount / 100).toFixed(2)}
							{event.body.result === TapAppInEventResult.PRE_AUTH_FAILED && ' (failed)'}
						</Typography>
					</Box>
				)}
				{event.body.isPriorityCustomer && (
					<Box sx={{ display: 'flex', flexDirection: 'row' }}>
						<PriorityHigh sx={{ fontSize: 13, color: Color.BLUE, mr: 1, mt: 'auto', mb: 'auto' }} />
						<Typography sx={{ color: Color.GRAY, fontSize: 13 }}>Priority Customer</Typography>
					</Box>
				)}
			</Box>

			{displayButton && (
				<Button
					size='small'
					variant='outlined'
					sx={{ mt: 'auto', mb: 'auto', ml: 'auto', color: Color.GRAY, borderColor: Color.GRAY, flexShrink: 1 }}
					target='_blank'
					href={link}
				>
					{success ? 'View Journey' : 'View Customer'}
				</Button>
			)}
		</Paper>
	);
};

export default TapAppInEventItem;

import { useAppSelector } from '../../datalayer/hooks';
import { StaffRole } from '../../models/StaffRole';

export function RoleGate({ children, requiredRole }: { children: JSX.Element; requiredRole: StaffRole | Array<StaffRole> }) {
	const roles = useAppSelector((state) => state.status.roles);

	const requiredRoles = Array.isArray(requiredRole) ? requiredRole : [requiredRole];

	if (!roles.some((role: StaffRole) => requiredRoles.includes(role))) {
		return null;
	}

	return children;
}

import React from 'react';
import Container from '@mui/material/Container';
import { Box, Button, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DatePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import Title from '../General/Title';

const AccuracySelector = () => {
	const navigate = useNavigate();

	const startOfLastMonth = DateTime.local().startOf('month').minus({ months: 1 });
	const tomorrow = DateTime.local().plus({ days: 1 });

	const [startDate, setStartDate] = React.useState(startOfLastMonth);
	const [endDate, setEndDate] = React.useState(tomorrow);

	const getAccuracyOverview = async () => {
		navigate(`/accuracy?startDate=${startDate.toISODate()}&endDate=${endDate.toISODate()}`);
	};

	return (
		<Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
			<Paper
				sx={{
					display: 'flex',
					flexDirection: 'column',
					mb: 4,
				}}
			>
				<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, pl: 2, pr: 2, pb: 2 }}>
					<Title>Accuracy Testing</Title>
				</Box>
				<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, pl: 2, pr: 2, pb: 2 }}>
					<DatePicker
						label='Start Date'
						value={startDate}
						sx={{ mr: 2 }}
						onChange={(newValue) => {
							//@ts-ignore
							setStartDate(newValue);
						}}
					/>
					<DatePicker
						label='End Date'
						value={endDate}
						sx={{ mr: 2 }}
						onChange={(newValue) => {
							//@ts-ignore
							setEndDate(newValue);
						}}
					/>

					<Button variant='contained' onClick={getAccuracyOverview} sx={{ flex: 1 }}>
						Calculate Accuracy
					</Button>
				</Box>
			</Paper>
		</Container>
	);
};

export default AccuracySelector;

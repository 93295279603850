import { Grid, Typography } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';

export const DateTimeSelector = ({
	startDate,
	endDate,
	setStartDate,
	setEndDate,
}: {
	startDate: Date | undefined;
	endDate: Date | undefined;
	setStartDate: (date: string | undefined) => void;
	setEndDate: (date: string | undefined) => void;
}) => {
	const [start, setStart] = useState<DateTime | null>(null);
	const [end, setEnd] = useState<DateTime | null>(null);

	useEffect(() => {
		if (startDate) {
			setStart(DateTime.fromJSDate(startDate));
		}
		if (endDate) {
			setEnd(DateTime.fromJSDate(endDate));
		}
	}, [startDate, endDate]);

	useEffect(() => {
		let startJsDate: Date | undefined | null = start?.toJSDate();
		let endJsDate: Date | undefined | null = end?.toJSDate();

		if (startJsDate?.toString() === 'Invalid Date') {
			startJsDate = null;
		}

		if (endJsDate?.toString() === 'Invalid Date') {
			endJsDate = null;
		}

		const startDateUpdated = startJsDate?.toISOString() != startDate?.toISOString();
		const endDateUpdated = endJsDate?.toISOString() != endDate?.toISOString();

		if (!startDateUpdated && !endDateUpdated) {
			return;
		}

		const timeout = setTimeout(() => {
			if (startDateUpdated) {
				setStartDate(start?.toJSDate().toISOString());
			}
			if (endDateUpdated) {
				setEndDate(end?.toJSDate().toISOString());
			}
		}, 500);

		return () => {
			clearTimeout(timeout);
		};
	}, [start, end]);

	return (
		<Grid container spacing={1} sx={{ pt: 2, pl: 2, pr: 2, pb: 2 }}>
			<Typography variant='subtitle1' sx={{ mt: 'auto', mb: 'auto', ml: 1, pt: 1 }}>
				Timeframe
			</Typography>
			<DateTimePicker
				label='From'
				value={start}
				sx={{ ml: 2, mt: 1 }}
				onChange={(newValue) => {
					setStart(newValue);
				}}
			/>
			<DateTimePicker
				label='Until'
				value={end}
				sx={{ ml: 2, mt: 1 }}
				onChange={(newValue) => {
					setEnd(newValue);
				}}
			/>
		</Grid>
	);
};

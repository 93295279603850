import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import EventList from '../components/MainPage/EventList';
import API from '../datalayer/API';
import Configuration from '../components/MainPage/Configuration';
import { useAppSelector } from '../datalayer/hooks';
import GreenwichGateArea from '../components/MainPage/GreenwichGateArea';
import LabstoreGateArea from '../components/MainPage/LabstoreGateArea';
import ReceiptCounts from '../components/MainPage/ReceiptCounts';

const MainPage = () => {
	const sseAllowed = useAppSelector((store) => store.status.sseAllowed);

	return (
		<Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
			<Grid container spacing={3}>
				{sseAllowed ? (
					<>
						<Grid item xs={12} md={6} lg={6}>
							{API.config.environment === 'prd' ? <GreenwichGateArea /> : <LabstoreGateArea />}
						</Grid>
						<Grid item xs={12} md={6} lg={6}>
							<Grid container spacing={3}>
								<Grid item xs={12} md={12} lg={12}>
									<Configuration />
								</Grid>
								<Grid item xs={12} md={12} lg={12}>
									<ReceiptCounts />
								</Grid>
							</Grid>
						</Grid>
					</>
				) : (
					<>
						<Grid item xs={12} md={6} lg={6}>
							<Configuration />
						</Grid>
						<Grid item xs={12} md={6} lg={6}>
							<ReceiptCounts />
						</Grid>
					</>
				)}

				<Grid item xs={12} md={12} lg={12}>
					<EventList />
				</Grid>
			</Grid>
		</Container>
	);
};

export default MainPage;

import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import {
	Alert,
	Box,
	Button,
	Chip,
	Divider,
	Grid,
	Paper,
	Snackbar,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Typography,
} from '@mui/material';
import {
	AccountCircle,
	Badge,
	Contactless,
	FormatListBulleted,
	HourglassBottom,
	Liquor,
	QrCodeScanner,
	ReceiptLong,
	Screenshot,
	Smartphone,
	Store,
	Videocam,
} from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import API from '../datalayer/API';
import DashboardJourney, { CustomerJourneyState, JourneyType } from '../models/DashboardJourney';
import { DateTimeSelector } from '../components/DateTimeSelector';
import ScrollEventEmitter from '../datalayer/ScrollEventEmitter';
import EventItem from '../components/General/EventItem';
import CheckinEvent from '../models/CheckinEvent';
import TapAppInEvent from '../models/TapAppInEvent';
import eventEmitter from '../datalayer/EventEmitter';
import { EventType } from '../models/EventType';
import AiFiEvent from '../models/AiFiEvent';
import ScanEvent from '../models/ScanEvent';
import { useAppSelector } from '../datalayer/hooks';

function useQuery() {
	const { search } = useLocation();

	return React.useMemo(() => new URLSearchParams(search), [search]);
}

const CustomerPage = () => {
	const query = useQuery();
	const navigate = useNavigate();

	const sseAllowed = useAppSelector((store) => store.status.sseAllowed);

	const sub: string | null = query.get('sub');

	const [snack, setSnack] = useState<{ message: string; severity: 'success' | 'error' | 'info' | 'warning' } | null>(null);

	//journeys

	const [journeysLoading, setJourneysLoading] = React.useState(false);
	const [hasMoreJourneys, setHasMoreJourneys] = React.useState(true);
	const [journeys, setJourneys] = React.useState<Array<DashboardJourney>>([]);
	const [journeyAmount, setJourneyAmount] = React.useState<number | null>(null);

	const fetchJourneys = async ({ limit, lastId, reset }: { limit: number; lastId?: string; reset?: boolean }) => {
		setJourneysLoading(true);
		API.getJourneys({
			limit,
			lastId,
			sub: sub ?? undefined,
		})
			.then((data) => {
				setHasMoreJourneys(data.length === limit);
				if (reset) {
					setJourneys(data);
				} else {
					setJourneys([...journeys, ...data]);
				}
				setJourneysLoading(false);
			})
			.catch((e) => {
				console.error(e);
				setJourneysLoading(false);
			});
	};

	const fetchJourneyAmount = async () => {
		setJourneyAmount(
			await API.getJourneyAmount({
				sub: sub ?? undefined,
			})
		);
	};

	useEffect(() => {
		if (!sub) {
			navigate('/');
			return;
		}
		fetchJourneys({ limit: 20, reset: true });
		fetchJourneyAmount();
	}, []);

	useEffect(() => {
		const bottomOfPageReached = () => {
			if (hasMoreJourneys && !journeysLoading) {
				const lastId = journeys.at(-1)?._id;
				fetchJourneys({ limit: 20, lastId });
			}
		};
		ScrollEventEmitter.on('bottom', bottomOfPageReached);
		return () => {
			ScrollEventEmitter.off('bottom', bottomOfPageReached);
		};
	}, [journeys, hasMoreJourneys, journeysLoading]);

	// last 10 checkin/appin/tapIn events

	const [checkinEvents, setCheckinEvents] = React.useState<Array<CheckinEvent | TapAppInEvent>>([]);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);
	const [endOfPageIds, setEndOfPageIds] = React.useState<Map<number, string>>(new Map());
	const [hasMoreCheckinEvents, setHasMoreCheckinEvents] = React.useState(true);

	const updateRowsPerPage = (rowsPerPage: number) => {
		setEndOfPageIds(new Map());
		setPage(0);
		setRowsPerPage(rowsPerPage);
	};

	const handleNewEvent = (event: AiFiEvent | ScanEvent | CheckinEvent | TapAppInEvent) => {
		if (![EventType.CHECKIN, EventType.APP_IN, EventType.TAP_IN].includes(event.type)) return;
		if ((event as TapAppInEvent | CheckinEvent).body.sub !== sub) return;
		if (page === 0) {
			let newEvents: Array<CheckinEvent | TapAppInEvent> = [];
			setCheckinEvents((events) => {
				newEvents = [event as CheckinEvent | TapAppInEvent, ...events];
				newEvents.pop();
				return newEvents;
			});

			if (newEvents.length > 0) {
				endOfPageIds.set(page, newEvents[newEvents.length - 1]._id);
				setEndOfPageIds(endOfPageIds);
			}
		}
	};

	const fetchCheckinEvents = () => {
		const lastId = endOfPageIds.get(page - 1);
		API.getEvents({ amount: rowsPerPage, lastId, sub: sub ?? undefined, types: [EventType.APP_IN, EventType.CHECKIN, EventType.TAP_IN] }).then((data) => {
			if (data.length > 0) {
				endOfPageIds.set(page, data[data.length - 1]._id);
				setEndOfPageIds(endOfPageIds);
			}
			setHasMoreCheckinEvents(!(data.length < rowsPerPage));
			setCheckinEvents(data as Array<CheckinEvent | TapAppInEvent>);
		});
	};

	useEffect(() => {
		fetchCheckinEvents();

		eventEmitter.addListener('storeEvent', handleNewEvent);

		return () => {
			eventEmitter.removeListener('storeEvent', handleNewEvent);
		};
	}, [page, rowsPerPage]);

	const simulateAppJourney = async () => {
		if (!sub) {
			return;
		}
		setSnack({ message: 'Scanning...', severity: 'info' });
		try {
			await API.simulateAppInEvent(sub);
			setSnack({ message: 'Scanned', severity: 'success' });
			if (!sseAllowed) {
				setPage(0);
			}
			await fetchJourneys({ limit: 20, reset: true });
		} catch (error) {
			console.error(error);
			setSnack({ message: 'Error scanning', severity: 'error' });
		} finally {
			setTimeout(() => {
				setSnack(null);
			}, 5000);
		}
	};

	if (!sub) {
		return null;
	}

	return (
		<Container maxWidth='xl' sx={{ mt: 4, mb: 4 }}>
			{snack ? (
				<Snackbar open={true} autoHideDuration={1000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
					<Alert severity={snack.severity} sx={{ width: '100%' }}>
						{snack.message}
					</Alert>
				</Snackbar>
			) : null}
			<Paper
				sx={{
					display: 'flex',
					flexDirection: 'column',
					mb: 4,
				}}
			>
				<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', pt: 2, pl: 2, pr: 2, pb: 2 }}>
					<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
						<Typography variant='h6'>
							{`Customer ${(() => {
								try {
									return JSON.parse(sub).customer_reference;
								} catch (e) {
									return sub;
								}
							})()}`}
						</Typography>
						{journeyAmount != null && <Typography variant='body2'>{`${journeyAmount} Journeys`}</Typography>}
					</Box>
					<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'right' }}>
						{API.config.environment === 'dev' && (
							<Button sx={{ mr: 2 }} variant='outlined' color='primary' startIcon={<QrCodeScanner />} onClick={() => simulateAppJourney()}>
								Simulate App Journey
							</Button>
						)}

						<Button
							sx={{ ml: 'auto' }}
							variant='outlined'
							color='primary'
							startIcon={<AccountCircle />}
							onClick={() => {
								window.open(`${API.config.sprykerBaseUrl}/customer/view?id-customer=${JSON.parse(sub).id_customer}`);
							}}
						>
							View Customer {JSON.parse(sub).customer_reference} in Spryker
						</Button>
					</Box>
				</Box>
			</Paper>
			<Paper sx={{ width: '100%', mb: 2, p: 2 }}>
				<Typography variant='h6'>Latest Check-In Events</Typography>
				{checkinEvents.map((event, index) => {
					return <EventItem event={event} key={event.timestamp + event.type} displayButton={true} displayFullDate={true} />;
				})}
				<TablePagination
					rowsPerPageOptions={[5, 10, 20]}
					component='div'
					count={hasMoreCheckinEvents ? -1 : page * rowsPerPage + checkinEvents.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={(_, page) => {
						setPage(page);
					}}
					onRowsPerPageChange={(e) => {
						updateRowsPerPage(parseInt(e.target.value, 10));
					}}
				/>
			</Paper>
			<Paper sx={{ width: '100%', mb: 2 }}>
				<Typography variant='h6' sx={{ p: 2 }}>
					Journeys
				</Typography>
				<TableContainer>
					<Table sx={{ minWidth: 650 }} aria-label='simple table'>
						<TableHead>
							<TableRow>
								<TableCell>Created At</TableCell>
								<TableCell align='right'>Journey ID</TableCell>
								<TableCell align='right'>Spryker Customer ID</TableCell>
								<TableCell align='right'>Type</TableCell>
								<TableCell align='right'>State</TableCell>
								<TableCell align='right'>Group Size</TableCell>
								<TableCell align='right'>Entry Gate ID</TableCell>
								<TableCell align='right'>Entry Time</TableCell>
								<TableCell align='right'>Exit Time</TableCell>
								<TableCell align='right'>Age Restriction</TableCell>
								<TableCell align='right'>Actions</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{journeys.map((journey: DashboardJourney) => (
								<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={journey.customerId}>
									<TableCell component='th' scope='row'>
										{journey.stateMillis[CustomerJourneyState.ANNOUNCEMENT]
											? new Date(journey.stateMillis[CustomerJourneyState.ANNOUNCEMENT]).toLocaleString()
											: ''}
									</TableCell>
									<TableCell align='right'>{journey.customerId}</TableCell>
									<TableCell align='right'>
										{JSON.parse(journey.sub).customer_reference}
										<br />
										{JSON.parse(journey.sub).id_customer}
									</TableCell>
									<TableCell align='right'>
										{journey.type === JourneyType.APP_IN && <Smartphone color='action' />}
										{journey.type === JourneyType.TAP_IN && <Contactless color='action' />}
									</TableCell>
									<TableCell align='right'>
										{journey.state === CustomerJourneyState.ANNOUNCEMENT && <QrCodeScanner color='action' />}
										{journey.state === CustomerJourneyState.CHECKIN && <Store color='action' />}
										{journey.state === CustomerJourneyState.LEFT && <HourglassBottom color='action' />}
										{journey.state === CustomerJourneyState.COMPLETED && <ReceiptLong color='action' />}
									</TableCell>

									<TableCell align='right'>{journey.maxCustomerAmount}</TableCell>
									<TableCell align='right'>
										{journey.entryGateId ? journey.entryGateId.substring(journey.entryGateId.length - 1) : ''}
									</TableCell>
									<TableCell align='right'>
										{journey.stateMillis[CustomerJourneyState.CHECKIN]
											? new Date(journey.stateMillis[CustomerJourneyState.CHECKIN]).toLocaleString()
											: ''}
									</TableCell>
									<TableCell align='right'>
										{journey.stateMillis[CustomerJourneyState.LEFT]
											? new Date(journey.stateMillis[CustomerJourneyState.LEFT]).toLocaleString()
											: ''}
									</TableCell>
									<TableCell align='right'>
										{journey.ageVerificationNecessary && <Liquor color='action' />}
										{journey.ageVerificationSuccessful && <Badge color='action' />}
									</TableCell>
									<TableCell align='right'>
										<Button variant='outlined' target='_blank' href={`/journey?customerId=${journey.customerId}`}>
											Details
										</Button>
									</TableCell>
								</TableRow>
							))}
							{journeysLoading ? (
								<TableRow>
									<TableCell align='center' colSpan={100}>
										<Typography variant='body2'>Loading...</Typography>
									</TableCell>
								</TableRow>
							) : null}
							{!hasMoreJourneys ? (
								<TableRow>
									<TableCell align='center' colSpan={100}>
										<Typography variant='body2'>
											{journeys.length === 0 ? 'No journeys found for current filter' : 'You have reached the end of the list'}
										</Typography>
									</TableCell>
								</TableRow>
							) : null}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</Container>
	);
};

export default CustomerPage;

import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Box, Button, Paper, Typography } from '@mui/material';
import { Group } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '../../datalayer/hooks';
import TitleLive from '../General/TitleLive';
import API from '../../datalayer/API';
import { setCurrentCustomerAmount, setMaxCustomerAmount } from '../../datalayer/Actions';

const Configuration = () => {
	const [lastRefresh, setLastRefresh] = useState(new Date());
	const currentCustomerAmount = useAppSelector((state) => state.store.currentCustomerAmount);
	const maxCustomerAmount = useAppSelector((state) => state.store.maxCustomerAmount);

	const sseAllowed = useAppSelector((store) => store.status.sseAllowed);
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (!sseAllowed) {
			loadData();
		}
	}, [sseAllowed]);

	useEffect(() => {
		setLastRefresh(new Date());
	}, [currentCustomerAmount, maxCustomerAmount]);

	const loadData = async () => {
		const { currentCustomerAmount, maxCustomerAmount } = await API.getCustomerLimit();

		dispatch(setCurrentCustomerAmount(currentCustomerAmount));
		dispatch(setMaxCustomerAmount(maxCustomerAmount));
		setLastRefresh(new Date());
	};

	const customerAmountStartDate = new Date(Date.now() - 1000 * 60 * 60 * 2);

	return (
		<Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
			<TitleLive sseRelevant={true} refresh={loadData} lastRefresh={lastRefresh}>
				Configuration
			</TitleLive>
			<Grid container spacing={3}>
				<Grid item xs={12} md={12} lg={12} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start', mt: 'auto' }}>
					<Group sx={{ fontSize: 30 }} color='action' />
					<Box sx={{ ml: 2, display: 'flex', flexDirection: 'column', flex: 1 }}>
						<Typography color='text.primary' variant='h6'>
							{maxCustomerAmount == null ? 'Customer Amount' : 'Customer Limit - active'}
						</Typography>
						<Box sx={{ display: 'flex', flexDirection: 'row' }}>
							<Typography color='text.secondary' sx={{ mt: 'auto', mb: 'auto' }}>{`${currentCustomerAmount}${
								maxCustomerAmount != null ? `/${maxCustomerAmount}` : ''
							} customers`}</Typography>
							<Button
								size='medium'
								href={`journeys?startDate=${customerAmountStartDate.toISOString()}&state=checkin`}
								sx={{ mt: 'auto', mb: 'auto', ml: 'auto' }}
							>
								Show Journeys
							</Button>
						</Box>
					</Box>
				</Grid>
			</Grid>
		</Paper>
	);
};

export default Configuration;

import { Navigate, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../datalayer/hooks';
import { RootState } from '../../datalayer/MainReducer';
import { deleteStore } from '../../datalayer/Actions';
import { useEffect } from 'react';
import { store } from '../../datalayer/store';

export function AuthGate({ children }: { children: JSX.Element }) {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const isAuthenticated = useAppSelector((state: RootState) => {
		return state.status.isSignedIn && state.status.accessTokenExpiryDate && new Date(state.status.accessTokenExpiryDate) > new Date();
	});

	useEffect(() => {
		if (window.location.pathname === '/login') {
			return;
		}
		const signOutIfTokenIsInvalid = () => {
			let totalSeconds = Math.floor((new Date(store.getState().status.accessTokenExpiryDate).valueOf() - new Date().valueOf()) / 1000);
			if (totalSeconds <= 0) {
				dispatch(deleteStore());
				navigate('/login', { replace: true, state: { from: window.location.pathname } });
			}
		};

		signOutIfTokenIsInvalid();

		const interval = setInterval(() => {
			signOutIfTokenIsInvalid();
		}, 1000);
		return () => {
			clearInterval(interval);
		};
	}, [dispatch, navigate]);

	if (!isAuthenticated) {
		dispatch(deleteStore());
		return <Navigate to='/login' state={{ from: window.location.pathname + window.location.search }} />;
	}

	return children;
}

import React, { useEffect } from 'react';
import API from '../datalayer/API';
import { DateTime } from 'luxon';
import ReceiptListItemDTO, { ReceiptStatus } from '../models/ReceiptListItemDTO';
import {
	Box,
	Button,
	Container,
	Divider,
	FormControl,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TextField,
	Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import Title from '../components/General/Title';
import { HourglassBottom, ShoppingCart } from '@mui/icons-material';
import { JourneyType } from '../models/DashboardJourney';

const ReceiptFinderPage = () => {
	const [date, setDate] = React.useState<DateTime | undefined>(undefined);
	const [transactionId, setTransactionId] = React.useState<string | undefined>(undefined);
	const [transactionValue, setTransactionValue] = React.useState<string | undefined>(undefined);
	const [firstDigits, setFirstDigits] = React.useState<string | undefined>(undefined);
	const [lastDigits, setLastDigits] = React.useState<string | undefined>(undefined);
	const [expiryDate, setExpiryDate] = React.useState<string | undefined>(undefined);
	const [journeyType, setJourneyType] = React.useState<JourneyType | undefined>(undefined);
	const [offset, setOffset] = React.useState<number>(0);
	const [limit, setLimit] = React.useState<number>(10);

	const [state, setState] = React.useState<'form' | 'loading' | 'results'>('form');

	const [receipts, setReceipts] = React.useState<Array<ReceiptListItemDTO>>([]);

	const findReceipts = async () => {
		if (!date && !transactionId && !transactionValue && !firstDigits && !lastDigits && !expiryDate && !journeyType) return;
		try {
			setState('loading');
			const expiryDateInFormat = expiryDate?.length === 5 ? '20' + expiryDate?.slice(3) + '-' + expiryDate?.slice(0, 2) : undefined;
			const res = await API.findReceipts({
				date: date?.toISODate() || undefined,
				transactionId: transactionId,
				transactionValue: Number(transactionValue) || undefined,
				firstDigits: firstDigits,
				lastDigits: lastDigits,
				expiryDate: expiryDateInFormat,
				journeyType: journeyType === JourneyType.APP_IN ? JourneyType.APP_IN : journeyType === JourneyType.TAP_IN ? JourneyType.TAP_IN : undefined,
				offset: offset,
				limit: limit,
			});
			setReceipts(res || []);
			setState('results');
		} catch (error) {
			console.error(error);
			setState('form');
		}
	};

	const formatter = new Intl.NumberFormat('en-GB', {
		style: 'currency',
		currency: 'GBP',
	});

	useEffect(() => {
		findReceipts();
	}, [offset, limit]);

	const setExpiryDateOverride = (value: string) => {
		const newExpiryDate = value;
		if (newExpiryDate.length === 3 && newExpiryDate[2] !== '/') {
			setExpiryDate(newExpiryDate.slice(0, 2) + '/' + newExpiryDate.slice(2));
			return;
		}
		setExpiryDate(newExpiryDate);
	};

	return (
		<Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
			<Paper
				sx={{
					display: 'flex',
					flexDirection: 'column',
					mb: 4,
				}}
			>
				<Box sx={{ display: 'flex', flexDirection: 'column', pt: 2, pl: 2, pr: 2, pb: 2 }}>
					<Title>Receipt Finder</Title>
					<Typography>Enter the information you have. The more fields you can fill, the better the results will be.</Typography>
				</Box>

				<Box sx={{ display: 'flex', flexDirection: 'column', pt: 2, pl: 2, pr: 2, pb: 2 }}>
					<FormControl fullWidth>
						<InputLabel id='journeyType-select-label'>Journey Type</InputLabel>
						<Select
							id='journeyType-select'
							labelId='journeyType-select-label'
							sx={{ mb: 2 }}
							value={journeyType}
							label='Journey Type'
							onChange={(e) => setJourneyType(e.target.value as JourneyType)}
							defaultValue={'all'}
						>
							<MenuItem value={'all'}>Tap In or App Journey</MenuItem>
							<MenuItem value={JourneyType.APP_IN}>App Journey</MenuItem>
							<MenuItem value={JourneyType.TAP_IN}>Tap In Journey</MenuItem>
						</Select>
					</FormControl>
					<DatePicker
						label='Shop date'
						value={date}
						sx={{ mb: 2 }}
						onChange={(newValue) => {
							//@ts-ignore
							setDate(newValue);
						}}
					/>
					<TextField
						label='5-digit Receipt No.'
						variant='outlined'
						type='number'
						value={transactionId}
						onChange={(newValue) => {
							setTransactionId(newValue.target.value);
						}}
						sx={{ mb: 2 }}
					/>
					<TextField
						label='Transaction Value (e.g. 12.34)'
						variant='outlined'
						type='number'
						value={transactionValue}
						onChange={(newValue) => {
							setTransactionValue(newValue.target.value);
						}}
						sx={{ mb: 2 }}
					/>
					<TextField
						label='First 6 Credit Card Digits (not available on Apple Pay and Google Pay)'
						variant='outlined'
						type='number'
						value={firstDigits}
						onChange={(newValue) => {
							setFirstDigits(newValue.target.value);
						}}
						sx={{ mb: 2 }}
					/>
					<TextField
						label='Last 4 Credit Card Digits (different from physical card on Apple Pay and Google Pay)'
						variant='outlined'
						type='number'
						value={lastDigits}
						onChange={(newValue) => {
							setLastDigits(newValue.target.value);
						}}
						sx={{ mb: 2 }}
					/>
					<TextField
						label='Credit Card Expiry Date (MM/YY, not available on Apple Pay and Google Pay)'
						variant='outlined'
						value={expiryDate}
						onChange={(newValue) => {
							setExpiryDateOverride(newValue.target.value);
						}}
						sx={{ mb: 2 }}
					/>

					<Button variant='contained' onClick={findReceipts} sx={{ flex: 1 }}>
						{state == 'loading' ? 'Loading...' : 'Find Receipts'}
					</Button>
				</Box>
			</Paper>
			{state === 'results' && (
				<Paper sx={{ width: '100%', mb: 2 }}>
					<TableContainer>
						<Table sx={{ minWidth: 650 }} aria-label='simple table'>
							<TableHead>
								<TableRow>
									<TableCell>Timestamp</TableCell>
									<TableCell align='right'>Journey ID</TableCell>
									<TableCell align='right'>State</TableCell>
									<TableCell align='right'>Item Count</TableCell>
									<TableCell align='right'>Transaction Value</TableCell>
									<TableCell align='right'>Actions</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{receipts.map((receipt: ReceiptListItemDTO) => (
									<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={receipt.id}>
										<TableCell component='th' scope='row'>
											{new Date(receipt.timestamp).toLocaleString()}
										</TableCell>
										<TableCell align='right'>{receipt.id}</TableCell>
										<TableCell align='right'>
											{receipt.state === ReceiptStatus.COMPLETED && <ShoppingCart color='action' />}
											{receipt.state === ReceiptStatus.PENDING && <HourglassBottom color='action' />}
										</TableCell>
										<TableCell align='right'>{receipt.itemCount}</TableCell>
										<TableCell align='right'>{`${formatter.format(receipt.transactionValue! / 100)}`}</TableCell>

										<TableCell align='right'>
											<Button variant='outlined' target='_blank' href={`/journey?customerId=${receipt.id}`}>
												Details
											</Button>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
					<Divider />
					<TablePagination
						rowsPerPageOptions={[20, 40, 60]}
						component='div'
						count={-1}
						rowsPerPage={limit}
						page={Math.floor(offset / limit)}
						onPageChange={(_, page) => {
							setOffset(page * limit);
						}}
						onRowsPerPageChange={(e) => {
							setLimit(parseInt(e.target.value, 10));
						}}
					/>
				</Paper>
			)}
		</Container>
	);
};

export default ReceiptFinderPage;

import React, { useState } from 'react';
import Container from '@mui/material/Container';
import { Alert, Box, Button, Paper, Snackbar, TextField } from '@mui/material';
import Title from '../General/Title';
import { Check } from '@mui/icons-material';
import API from '../../datalayer/API';

const IssueReportForm = () => {
	const [snack, setSnack] = useState<{ message: string; severity: 'success' | 'error' | 'info' | 'warning' } | null>(null);

	const [description, setDescription] = useState('');

	const createIssueReport = async () => {
		try {
			await API.createIssueReport(description);
			setSnack({ message: 'Thanks for reporting the issue', severity: 'success' });
			setDescription('');
		} catch (e) {
			setSnack({ message: 'There was an Issue reporting the Issue', severity: 'error' });
		} finally {
			setTimeout(() => {
				setSnack(null);
			}, 5000);
		}
	};

	return (
		<Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
			{snack ? (
				<Snackbar open={true} autoHideDuration={1000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
					<Alert severity={snack.severity} sx={{ width: '100%' }}>
						{snack.message}
					</Alert>
				</Snackbar>
			) : null}
			<Paper
				sx={{
					display: 'flex',
					flexDirection: 'column',
					mb: 4,
				}}
			>
				<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, pl: 2, pr: 2, pb: 2 }}>
					<Title>Log an Issue</Title>
				</Box>
				<Box sx={{ display: 'flex', flexDirection: 'column', pl: 2, pr: 2, pb: 2 }}>
					<TextField
						value={description}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							setDescription(event.target.value);
						}}
						id='content'
						label='Please provide a brief summary of the issue (e.g. account locked without declined payment)'
						variant='outlined'
						multiline
						minRows={3}
					/>
					<Button
						disabled={!(description?.length > 0)}
						variant='contained'
						startIcon={<Check />}
						onClick={createIssueReport}
						sx={{ flex: 1, pt: 2, pb: 2, mt: 2 }}
					>
						Submit
					</Button>
				</Box>
			</Paper>
		</Container>
	);
};

export default IssueReportForm;

import React, { useEffect } from 'react';
import Container from '@mui/material/Container';
import { Box, Button, Chip, Divider, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import {
	CurrencyPound,
	FormatListBulleted,
	HourglassBottom,
	Payment,
	ReceiptLong,
	ShoppingCartCheckout,
	Store,
	ShoppingCart,
	ProductionQuantityLimits,
	CreditScore,
	Healing,
	Error,
} from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import API from '../datalayer/API';
import DashboardOrder, { BopState, OrderType, PaymentState } from '../models/DashboardOrder';
import { DateTimeSelector } from '../components/DateTimeSelector';
import ScrollEventEmitter from '../datalayer/ScrollEventEmitter';

function useQuery() {
	const { search } = useLocation();

	return React.useMemo(() => new URLSearchParams(search), [search]);
}

const OrderPage = () => {
	const query = useQuery();
	const navigate = useNavigate();

	const [loading, setLoading] = React.useState(false);
	const [hasMore, setHasMore] = React.useState(true);

	const selectedStartDate: string | undefined = query.get('startDate') ? new Date(query.get('startDate') as string).toISOString() : undefined;
	const selectedEndDate: string | undefined = query.get('endDate') ? new Date(query.get('endDate') as string).toISOString() : undefined;
	const selectedPaymentState: PaymentState | undefined = (query.get('paymentState') ?? undefined) as PaymentState | undefined;
	const selectedBopState: BopState | undefined = (query.get('bopState') ?? undefined) as BopState | undefined;
	const selectedOrderType: OrderType | undefined = (query.get('type') ?? undefined) as OrderType | undefined;
	const aifiOrderId: string | undefined = query.get('aifiOrderId') ?? (undefined as string | undefined);

	const setSelectedStartDate = (selectedDate: string | undefined) => {
		const params = new URLSearchParams();
		if (selectedDate) params.append('startDate', selectedDate);
		if (selectedEndDate) params.append('endDate', selectedEndDate);
		if (selectedPaymentState) params.append('paymentState', selectedPaymentState);
		if (selectedBopState) params.append('bopState', selectedBopState);
		if (selectedOrderType) params.append('type', selectedOrderType);
		navigate(`/orders?${params.toString()}`);
	};

	const setSelectedEndDate = (selectedDate: string | undefined) => {
		const params = new URLSearchParams();
		if (selectedStartDate) params.append('startDate', selectedStartDate);
		if (selectedDate) params.append('endDate', selectedDate);
		if (selectedPaymentState) params.append('paymentState', selectedPaymentState);
		if (selectedBopState) params.append('bopState', selectedBopState);
		if (selectedOrderType) params.append('type', selectedOrderType);
		navigate(`/orders?${params.toString()}`);
	};

	const setSelectedPaymentState = (selectedState: PaymentState | undefined) => {
		const params = new URLSearchParams();
		if (selectedState) params.append('paymentState', selectedState);
		if (selectedBopState) params.append('bopState', selectedBopState);
		if (selectedOrderType) params.append('type', selectedOrderType);
		if (selectedStartDate) params.append('startDate', selectedStartDate);
		if (selectedEndDate) params.append('endDate', selectedEndDate);
		navigate(`/orders?${params.toString()}`);
	};

	const setSelectedBopState = (selectedState: BopState | undefined) => {
		const params = new URLSearchParams();
		if (selectedState) params.append('bopState', selectedState);
		if (selectedPaymentState) params.append('paymentState', selectedPaymentState);
		if (selectedOrderType) params.append('type', selectedOrderType);
		if (selectedStartDate) params.append('startDate', selectedStartDate);
		if (selectedEndDate) params.append('endDate', selectedEndDate);
		navigate(`/orders?${params.toString()}`);
	};

	const setSelectedOrderType = (selectedType: OrderType | undefined) => {
		const params = new URLSearchParams();
		if (selectedType) params.append('type', selectedType);
		if (selectedPaymentState) params.append('paymentState', selectedPaymentState);
		if (selectedBopState) params.append('bopState', selectedBopState);
		if (selectedStartDate) params.append('startDate', selectedStartDate);
		if (selectedEndDate) params.append('endDate', selectedEndDate);
		navigate(`/orders?${params.toString()}`);
	};

	const [orders, setOrders] = React.useState<Array<DashboardOrder>>([]);
	const [amountForFilter, setAmountForFilter] = React.useState<number | null>(null);

	const fetchOrders = async ({ limit, lastId, reset }: { limit: number; lastId?: string; reset?: boolean }) => {
		setLoading(true);
		API.getOrders({
			limit,
			lastId,
			paymentState: selectedPaymentState,
			bopState: selectedBopState,
			type: selectedOrderType,
			startDate: selectedStartDate ? new Date(selectedStartDate) : undefined,
			endDate: selectedEndDate ? new Date(selectedEndDate) : undefined,
			aifiOrderId: aifiOrderId,
		})
			.then((data) => {
				setHasMore(data.length === limit);
				if (reset) {
					setOrders(data);
				} else {
					setOrders([...orders, ...data]);
				}
				setLoading(false);
			})
			.catch((e) => {
				console.error(e);
				setLoading(false);
			});
	};

	useEffect(() => {
		fetchOrders({ limit: 20, reset: true });
	}, [selectedPaymentState, selectedBopState, selectedOrderType, selectedStartDate, selectedEndDate, aifiOrderId]);

	useEffect(() => {
		getOrderAmount();
	}, [selectedPaymentState, selectedBopState, selectedOrderType, selectedStartDate, selectedEndDate]);

	const getOrderAmount = async () => {
		setAmountForFilter(
			await API.getOrderAmount({
				paymentState: selectedPaymentState,
				bopState: selectedBopState,
				type: selectedOrderType,
				startDate: selectedStartDate ? new Date(selectedStartDate) : undefined,
				endDate: selectedEndDate ? new Date(selectedEndDate) : undefined,
			})
		);
	};

	useEffect(() => {
		const bottomOfPageReached = () => {
			if (hasMore && !loading) {
				const lastId = orders.at(-1)?._id;
				fetchOrders({ limit: 20, lastId });
			}
		};
		ScrollEventEmitter.on('bottom', bottomOfPageReached);
		return () => {
			ScrollEventEmitter.off('bottom', bottomOfPageReached);
		};
	}, [orders, hasMore, loading]);

	return (
		<Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
			<Paper
				sx={{
					display: 'flex',
					flexDirection: 'column',
					mb: 4,
				}}
			>
				<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
					<Typography variant='h6' sx={{ pt: 2, pl: 2, pr: 2, pb: 2 }}>
						Orders
					</Typography>
					<Typography variant='body1' sx={{ pt: 2, pl: 2, pr: 2, pb: 2 }}>
						{amountForFilter != null ? `orders for current filter: ${amountForFilter}` : ''}
					</Typography>
				</Box>
				<Divider />
				<DateTimeSelector
					startDate={selectedStartDate ? new Date(selectedStartDate) : undefined}
					endDate={selectedEndDate ? new Date(selectedEndDate) : undefined}
					setStartDate={setSelectedStartDate}
					setEndDate={setSelectedEndDate}
				/>
				<Divider />
				<Grid container spacing={1} sx={{ pt: 2, pl: 2, pr: 2, pb: 2 }}>
					<Typography variant='subtitle1' sx={{ mt: 'auto', mb: 'auto', ml: 1, mr: 1 }}>
						Order State
					</Typography>
					<Grid item>
						<Chip
							variant={!selectedPaymentState ? 'filled' : 'outlined'}
							size='medium'
							label='Show All'
							icon={<FormatListBulleted />}
							onClick={() => {
								setSelectedPaymentState(undefined);
							}}
						/>
					</Grid>
					<Grid item>
						<Chip
							variant={selectedPaymentState === PaymentState.CANCELLED ? 'filled' : 'outlined'}
							size='medium'
							label='Cancelled'
							icon={<Error />}
							onClick={() => {
								setSelectedPaymentState(PaymentState.CANCELLED);
							}}
						/>
					</Grid>
					<Grid item>
						<Chip
							variant={selectedPaymentState === PaymentState.PRICING_PENDING ? 'filled' : 'outlined'}
							size='medium'
							label='Order Created'
							icon={<ReceiptLong />}
							onClick={() => {
								setSelectedPaymentState(PaymentState.PRICING_PENDING);
							}}
						/>
					</Grid>
					<Grid item>
						<Chip
							variant={selectedPaymentState === PaymentState.DIRECT_CHECKOUT_PENDING ? 'filled' : 'outlined'}
							size='medium'
							label='Prices Calculated'
							icon={<CurrencyPound />}
							onClick={() => {
								setSelectedPaymentState(PaymentState.DIRECT_CHECKOUT_PENDING);
							}}
						/>
					</Grid>
					<Grid item>
						<Chip
							variant={selectedPaymentState === PaymentState.PAYMENT_PENDING ? 'filled' : 'outlined'}
							size='medium'
							label='Order in Spryker'
							icon={<ShoppingCartCheckout />}
							onClick={() => {
								setSelectedPaymentState(PaymentState.PAYMENT_PENDING);
							}}
						/>
					</Grid>
					<Grid item>
						<Chip
							variant={selectedPaymentState === PaymentState.PARTIAL_PAYMENT_PENDING ? 'filled' : 'outlined'}
							size='medium'
							label='Partial Payment Confirmed'
							icon={<Payment />}
							onClick={() => {
								setSelectedPaymentState(PaymentState.PARTIAL_PAYMENT_PENDING);
							}}
						/>
					</Grid>
					<Grid item>
						<Chip
							variant={selectedPaymentState === PaymentState.COMPLETED ? 'filled' : 'outlined'}
							size='medium'
							label={'Payment Confirmed'}
							icon={<CreditScore />}
							onClick={() => {
								setSelectedPaymentState(PaymentState.COMPLETED);
							}}
						/>
					</Grid>
				</Grid>
				<Divider />
				<Grid container spacing={1} sx={{ pt: 2, pl: 2, pr: 2, pb: 2 }}>
					<Typography variant='subtitle1' sx={{ mt: 'auto', mb: 'auto', ml: 1, mr: 1 }}>
						Store 2014
					</Typography>
					<Grid item>
						<Chip
							variant={!selectedBopState ? 'filled' : 'outlined'}
							size='medium'
							label='Show All'
							icon={<FormatListBulleted />}
							onClick={() => {
								setSelectedBopState(undefined);
							}}
						/>
					</Grid>
					<Grid item>
						<Chip
							variant={selectedBopState === BopState.CANCELLED ? 'filled' : 'outlined'}
							size='medium'
							label='Cancelled'
							icon={<Error />}
							onClick={() => {
								setSelectedBopState(BopState.CANCELLED);
							}}
						/>
					</Grid>
					<Grid item>
						<Chip
							variant={selectedBopState === BopState.PENDING ? 'filled' : 'outlined'}
							size='medium'
							label='Pending'
							icon={<HourglassBottom />}
							onClick={() => {
								setSelectedBopState(BopState.PENDING);
							}}
						/>
					</Grid>
					<Grid item>
						<Chip
							variant={selectedBopState === BopState.UPDATED_ORDER_PENDING ? 'filled' : 'outlined'}
							size='medium'
							label='Updated Order Pending'
							icon={<Healing />}
							onClick={() => {
								setSelectedBopState(BopState.UPDATED_ORDER_PENDING);
							}}
						/>
					</Grid>
					<Grid item>
						<Chip
							variant={selectedBopState === BopState.COMPLETED ? 'filled' : 'outlined'}
							size='medium'
							label={'Inserted'}
							icon={<Store />}
							onClick={() => {
								setSelectedBopState(BopState.COMPLETED);
							}}
						/>
					</Grid>
				</Grid>
				<Divider />
				<Grid container spacing={1} sx={{ pt: 2, pl: 2, pr: 2, pb: 2 }}>
					<Typography variant='subtitle1' sx={{ mt: 'auto', mb: 'auto', ml: 1, mr: 1 }}>
						Type
					</Typography>
					<Grid item>
						<Chip
							variant={!selectedOrderType ? 'filled' : 'outlined'}
							size='medium'
							label='Show All'
							icon={<FormatListBulleted />}
							onClick={() => {
								setSelectedOrderType(undefined);
							}}
						/>
					</Grid>
					<Grid item>
						<Chip
							variant={selectedOrderType === OrderType.ORDER ? 'filled' : 'outlined'}
							size='medium'
							label='Order'
							icon={<ShoppingCart />}
							onClick={() => {
								setSelectedOrderType(OrderType.ORDER);
							}}
						/>
					</Grid>
					<Grid item>
						<Chip
							variant={selectedOrderType === OrderType.REFUND ? 'filled' : 'outlined'}
							size='medium'
							label={'Refund'}
							icon={<ProductionQuantityLimits />}
							onClick={() => {
								setSelectedOrderType(OrderType.REFUND);
							}}
						/>
					</Grid>
					<Grid item>
						<Chip
							variant={selectedOrderType === OrderType.UPDATED_ORDER ? 'filled' : 'outlined'}
							size='medium'
							label={'Updated Order'}
							icon={<Healing />}
							onClick={() => {
								setSelectedOrderType(OrderType.UPDATED_ORDER);
							}}
						/>
					</Grid>
				</Grid>
			</Paper>
			<Paper sx={{ width: '100%', mb: 2 }}>
				<TableContainer>
					<Table sx={{ minWidth: 650 }} aria-label='simple table'>
						<TableHead>
							<TableRow>
								<TableCell>Created At</TableCell>
								<TableCell align='right'>Journey ID</TableCell>
								<TableCell align='right'>Type</TableCell>
								<TableCell align='right'>Order State</TableCell>
								<TableCell align='right'>Store 2014</TableCell>
								<TableCell align='right'>Items</TableCell>
								<TableCell align='right'>Actions</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{orders.map((order: DashboardOrder) => (
								<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={order.orderReceiptId}>
									<TableCell component='th' scope='row'>
										{order.bopStateMillis[BopState.PENDING] ? new Date(order.bopStateMillis[BopState.PENDING]).toLocaleString() : 'N/A'}
									</TableCell>
									<TableCell align='right'>{order.journeyCustomerId}</TableCell>
									<TableCell align='right'>
										{order.type === OrderType.ORDER && <ShoppingCart color='action' />}
										{order.type === OrderType.REFUND && <ProductionQuantityLimits color='action' />}
										{order.type === OrderType.UPDATED_ORDER && <Healing color='action' />}
									</TableCell>

									<TableCell align='right'>
										{order.paymentState === PaymentState.CANCELLED && <Error color='action' />}
										{order.paymentState === PaymentState.PRICING_PENDING && <ReceiptLong color='action' />}
										{order.paymentState === PaymentState.DIRECT_CHECKOUT_PENDING && <CurrencyPound color='action' />}
										{order.paymentState === PaymentState.PAYMENT_PENDING && <ShoppingCartCheckout color='action' />}
										{order.paymentState === PaymentState.PARTIAL_PAYMENT_PENDING && <Payment color='action' />}
										{order.paymentState === PaymentState.COMPLETED && <CreditScore color='action' />}
									</TableCell>

									<TableCell align='right'>
										{order.bopState === BopState.CANCELLED && <Error color='action' />}
										{order.bopState === BopState.PENDING && <HourglassBottom color='action' />}
										{order.bopState === BopState.UPDATED_ORDER_PENDING && <Healing color='action' />}
										{order.bopState === BopState.COMPLETED && <Store color='action' />}
									</TableCell>
									<TableCell align='right'>
										{[OrderType.ORDER, OrderType.UPDATED_ORDER].includes(order.type)
											? order.cart?.items?.reduce((acc, item) => acc + item.amount, 0)
											: order.refundRequest?.items.reduce((acc, item) => acc + item.itemCount, 0)}
									</TableCell>

									<TableCell align='right'>
										<Button variant='outlined' target='_blank' href={`/journey?customerId=${order.journeyCustomerId}`}>
											Details
										</Button>
									</TableCell>
								</TableRow>
							))}
							{loading ? (
								<TableRow>
									<TableCell align='center' colSpan={100}>
										<Typography variant='body2'>Loading...</Typography>
									</TableCell>
								</TableRow>
							) : null}
							{!hasMore ? (
								<TableRow>
									<TableCell align='center' colSpan={100}>
										<Typography variant='body2'>
											{orders.length === 0 ? 'No orders found for current filter' : 'You have reached the end of the list'}
										</Typography>
									</TableCell>
								</TableRow>
							) : null}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</Container>
	);
};

export default OrderPage;

export default class TapAppInEventPayload {
	gateId: string;
	customerId: number | null;
	sub: string;
	isNewCustomer: boolean;
	isPriorityCustomer: boolean;
	activeConsentGiven: boolean;
	preAuthPerformed: boolean;
	preAuthSuccessful: boolean;
	preAuthAmount: number | null;
	result: TapAppInEventResult;

	constructor(
		gateId: string,
		customerId: number | null,
		sub: string,
		isNewCustomer: boolean,
		isPriorityCustomer: boolean,
		activeConsentGiven: boolean,
		preAuthPerformed: boolean,
		preAuthSuccessful: boolean,
		preAuthAmount: number | null,
		result: TapAppInEventResult
	) {
		this.gateId = gateId;
		this.customerId = customerId;
		this.sub = sub;
		this.isNewCustomer = isNewCustomer;
		this.isPriorityCustomer = isPriorityCustomer;
		this.activeConsentGiven = activeConsentGiven;
		this.preAuthPerformed = preAuthPerformed;
		this.preAuthSuccessful = preAuthSuccessful;
		this.preAuthAmount = preAuthAmount;
		this.result = result;
	}
}

export enum TapAppInEventResult {
	OK = 'OK',
	GROUP_ENTRY_OK = 'GROUP_ENTRY_OK',

	SUPPORT = 'SUPPORT',

	FIRST_TAP_ON_GROUP_SHOPPING_TERMINAL = 'FIRST_TAP_ON_GROUP_SHOPPING_TERMINAL',

	USER_LOCKED = 'USER_LOCKED',
	USER_HAS_NO_CREDIT_CARD = 'USER_HAS_NO_CREDIT_CARD',
	USER_HAS_NO_ACTIVE_CREDIT_CARD = 'USER_HAS_NO_ACTIVE_CREDIT_CARD',
	USER_HAS_EXPIRED_CREDIT_CARD = 'USER_HAS_EXPIRED_CREDIT_CARD',
	USER_HAS_LOCKED_CREDIT_CARD = 'USER_HAS_LOCKED_CREDIT_CARD',
	USER_HAS_SOFT_DECLINED_CREDIT_CARD = 'USER_HAS_SOFT_DECLINED_CREDIT_CARD',
	USER_HAS_DECLINED_PAYMENTS = 'USER_HAS_DECLINED_PAYMENTS',
	USER_HAS_DECLINED_PAYMENTS_OUT_OF_RETRIES = 'USER_HAS_DECLINED_PAYMENTS_OUT_OF_RETRIES',
	USER_HAS_TOO_MANY_OPEN_RECEIPTS = 'USER_HAS_TOO_MANY_OPEN_RECEIPTS',

	INCOMPATIBLE_PAYMENT_METHOD = 'INCOMPATIBLE_PAYMENT_METHOD',

	GATE_IN_USE = 'GATE_IN_USE',
	GATE_TIMEOUT_PENDING = 'GATE_TIMEOUT_PENDING',

	ACTIVE_CONSENT_DECLINED = 'ACTIVE_CONSENT_DECLINED',
	PRE_AUTH_FAILED = 'PRE_AUTH_FAILED',

	AIFI_CUSTOMER_CREATION_ERROR = 'AIFI_CUSTOMER_CREATION_ERROR',
	AIFI_CODE_VERIFICATION_ERROR = 'AIFI_CODE_VERIFICATION_ERROR',

	INVALID_CODE = 'INVALID_CODE',

	INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
}

import { CreditScore, CurrencyPound, Payment, ReceiptLong, ShoppingCartCheckout } from '@mui/icons-material';
import CustomProgressBar from './CustomProgressBar';
import { OrderType, PaymentState } from '../../models/DashboardOrder';

const OrderPaymentProgressBar = ({ activeState, stateMillis, type }: { activeState: PaymentState; stateMillis: any; type: OrderType }) => {
	const states = [];

	states.push({
		state: PaymentState.PRICING_PENDING,
		label: type !== OrderType.REFUND ? 'Order' : 'Refund',
		sublabel: 'created at',
		icon: ReceiptLong,
	});

	states.push({
		state: PaymentState.DIRECT_CHECKOUT_PENDING,
		label: 'Prices',
		sublabel: 'calculated at',
		icon: CurrencyPound,
	});

	states.push({
		state: PaymentState.PAYMENT_PENDING,
		label: type !== OrderType.REFUND ? 'Order in Spryker' : 'Refund in Spryker',
		sublabel: 'processed at',
		icon: ShoppingCartCheckout,
	});

	if (activeState === PaymentState.PARTIAL_PAYMENT_PENDING) {
		states.push({
			state: PaymentState.PARTIAL_PAYMENT_PENDING,
			label: 'Partial Payment',
			sublabel: 'captured at',
			icon: Payment,
		});
	}

	states.push({
		state: PaymentState.COMPLETED,
		label: type !== OrderType.REFUND ? 'Payment' : 'Reimbursement',
		sublabel: 'captured at',
		icon: CreditScore,
	});

	return <CustomProgressBar activeState={activeState} stateMillis={stateMillis} states={states} />;
};

export default OrderPaymentProgressBar;

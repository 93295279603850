import { combineReducers } from '@reduxjs/toolkit';
import StatusReducer from './StatusReducer';
import Status from '../models/Status';
import StoreReducer from './StoreReducer';

export default combineReducers({
	status: StatusReducer,
	store: StoreReducer,
});

export type RootState = {
	status: Status;
};

export default class Status {
	isSignedIn: boolean;
	accessTokenExpiryDate: string;
	roles: Array<string>;
	sseConnected: boolean;
	sseAllowed: boolean;

	constructor(isSignedIn: boolean, accessTokenExpiryDate: string, roles: Array<string>, sseConnected: boolean, sseAllowed: boolean) {
		this.isSignedIn = isSignedIn;
		this.accessTokenExpiryDate = accessTokenExpiryDate;
		this.roles = roles;
		this.sseConnected = sseConnected;
		this.sseAllowed = sseAllowed;
	}
}

import * as React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { QrCodeScanner, ErrorOutline, ShoppingCart, NotInterestedOutlined, Person, CurrencyPound, PriorityHigh } from '@mui/icons-material';
import { Color } from '../../assets/Color';
import Box from '@mui/material/Box';
import CheckinEvent from '../../models/CheckinEvent';
import { CheckinEventType } from '../../models/CheckinEventPayload';
import { Paper } from '@mui/material';

const CheckinEventItem: React.FC<{ event: CheckinEvent; displayButton: boolean; displayFullDate: boolean }> = ({ event, displayButton, displayFullDate }) => {
	let link = '/journey';
	if (event.body.result !== CheckinEventType.ERROR) {
		link += `?customerId=${(event as CheckinEvent).body.customerId}`;
	} else if (event.body.result === CheckinEventType.ERROR) {
		link += `s?sub=${(event as CheckinEvent).body.sub}`;
	}

	const sub = JSON.parse(event.body.sub);

	return (
		<Paper elevation={3} sx={{ display: 'flex', mt: 2, p: 2 }}>
			{event.body.result !== CheckinEventType.ERROR ? (
				<QrCodeScanner sx={{ fontSize: 30, color: Color.BLUE, mr: 2, mt: 'auto', mb: 'auto', flexShrink: 1 }} color='primary' />
			) : (
				<Box sx={{ display: 'grid', mt: 'auto', mb: 'auto', flexShrink: 1 }}>
					<QrCodeScanner sx={{ fontSize: 30, color: Color.LIGHT_GRAY, mr: 2, gridColumn: 1, gridRow: 1 }} color='primary' />
					<NotInterestedOutlined sx={{ fontSize: 30, color: Color.RED, mr: 2, gridColumn: 1, gridRow: 1 }} />
				</Box>
			)}
			<Box sx={{ display: 'flex', flexDirection: 'column', mr: 1, mt: 'auto', mb: 'auto', flexShrink: 1 }}>
				<Typography sx={{ fontWeight: 'bold', fontSize: 14 }}>
					{event.body.result === CheckinEventType.OK
						? 'CHECK-IN'
						: event.body.result === CheckinEventType.REUSE
						? 'CHECK-IN RELOADED'
						: 'CHECK-IN FAILED'}
				</Typography>
				<Typography sx={{ color: Color.GRAY, fontSize: 13 }}>
					{displayFullDate ? new Date(event.timestamp).toLocaleString() : new Date(event.timestamp).toLocaleTimeString()}
				</Typography>
			</Box>
			{event.body.result === CheckinEventType.ERROR && (
				<Box sx={{ display: 'flex', flexDirection: 'row', ml: 'auto', mr: 1, mt: 'auto', mb: 'auto', flexShrink: 999999 }}>
					<ErrorOutline sx={{ fontSize: 13, color: Color.RED, mr: 1, mt: 'auto', mb: 'auto' }} />
					<Typography sx={{ color: Color.GRAY, fontSize: 13, fontWeight: 'bold' }}>{event.body.errorMessage}</Typography>
				</Box>
			)}
			<Box sx={{ display: 'flex', flexDirection: 'column', ml: 'auto', mr: 1, mt: 'auto', mb: 'auto', flexShrink: 1 }}>
				<Box sx={{ display: 'flex', flexDirection: 'row' }}>
					<Person sx={{ fontSize: 13, color: Color.BLUE, mr: 1, mt: 'auto', mb: 'auto' }} />
					<Typography sx={{ color: Color.GRAY, fontSize: 13 }}>{sub.customer_reference}</Typography>
				</Box>
				<Box sx={{ display: 'flex', flexDirection: 'row' }}>
					<ShoppingCart sx={{ fontSize: 13, color: Color.BLUE, mr: 1, mt: 'auto', mb: 'auto' }} />
					<Typography sx={{ color: Color.GRAY, fontSize: 13 }}>{event.body.customerId}</Typography>
				</Box>
			</Box>
			{event.body.sprykerResult != null && (event.body.sprykerResult.preAuthAmount != null || event.body.sprykerResult.isPriorityCustomer) && (
				<Box sx={{ display: 'flex', flexDirection: 'column', ml: 'auto', mr: 1, mt: 'auto', mb: 'auto', flexShrink: 1 }}>
					{event.body.sprykerResult.preAuthAmount != null && (
						<Box sx={{ display: 'flex', flexDirection: 'row' }}>
							<CurrencyPound sx={{ fontSize: 13, color: Color.BLUE, mr: 1, mt: 'auto', mb: 'auto' }} />
							<Typography sx={{ color: Color.GRAY, fontSize: 13 }}>
								{(event.body.sprykerResult.preAuthAmount / 100).toFixed(2)}
								{!event.body.sprykerResult.preAuthSuccessful && ' (failed)'}
							</Typography>
						</Box>
					)}
					{event.body.sprykerResult.isPriorityCustomer && (
						<Box sx={{ display: 'flex', flexDirection: 'row' }}>
							<PriorityHigh sx={{ fontSize: 13, color: Color.BLUE, mr: 1, mt: 'auto', mb: 'auto' }} />
							<Typography sx={{ color: Color.GRAY, fontSize: 13 }}>Priority Customer</Typography>
						</Box>
					)}
				</Box>
			)}

			{displayButton && (
				<Button
					size='small'
					variant='outlined'
					sx={{ mt: 'auto', mb: 'auto', ml: 'auto', color: Color.GRAY, borderColor: Color.GRAY, flexShrink: 1 }}
					target='_blank'
					href={link}
				>
					{event.body.result !== CheckinEventType.ERROR ? 'View Journey' : 'View Customer'}
				</Button>
			)}
		</Paper>
	);
};

export default CheckinEventItem;

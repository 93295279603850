import * as React from 'react';
import ScanEvent from '../../models/ScanEvent';
import AiFiEvent from '../../models/AiFiEvent';
import { EventType } from '../../models/EventType';
import CheckinEvent from '../../models/CheckinEvent';
import LegacyEventItem from './LegacyEventItem';
import ScanEventItem from './ScanEventItem';
import CheckinEventItem from './CheckinEventItem';
import AiFiEventItem from './AiFiEventItem';
import TapAppInEvent from '../../models/TapAppInEvent';
import TapAppInEventItem from './TapAppInEventItem';

const EventItem: React.FC<{ event: ScanEvent | AiFiEvent | CheckinEvent | TapAppInEvent; displayButton: boolean; displayFullDate: boolean }> = ({
	event,
	displayButton,
	displayFullDate,
}) => {
	switch (event.type) {
		case EventType.TAP_IN:
		case EventType.APP_IN:
			return <TapAppInEventItem event={event as TapAppInEvent} displayButton={displayButton} displayFullDate={displayFullDate} />;
		case EventType.CHECKIN:
			return <CheckinEventItem event={event as CheckinEvent} displayButton={displayButton} displayFullDate={displayFullDate} />;
		case EventType.SCAN:
			return <ScanEventItem event={event as ScanEvent} displayButton={displayButton} displayFullDate={displayFullDate} />;
		case EventType.ENTERED:
		case EventType.LEFT:
		case EventType.CHECKOUT:
		case EventType.CHECKOUT_ZONE_ENTERED:
			return <AiFiEventItem event={event as AiFiEvent} displayButton={displayButton} displayFullDate={displayFullDate} />;
		default:
			return <LegacyEventItem event={event} displayButton={displayButton} displayFullDate={displayFullDate} />;
	}
};

export default EventItem;

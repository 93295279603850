import React, { useEffect } from 'react';
import Container from '@mui/material/Container';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import API from '../../datalayer/API';
import { BakeryDining, Category, ErrorOutline, Group, Insights, Person, QrCodeScanner, ReceiptLong, ShoppingCart } from '@mui/icons-material';
import AccuracyTestingOverviewDTO from '../../models/AccuracyTestingModels';
import Title from '../General/Title';
import { Color } from '../../assets/Color';

const AccuracyOverview = ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
	const [accuracy, setAccuracy] = React.useState<AccuracyTestingOverviewDTO>({
		startDate: startDate,
		endDate: endDate,
		totalJourneys: 0,
		journeysWithErrors: 0,
		totalItems: 0,
		itemsWithErrors: 0,
		testingJourneys: [],
	});

	const reviewedJourneyCount = accuracy.testingJourneys.filter((journey) => journey.reviewed).length;

	useEffect(() => {
		if (!startDate || !endDate) return;
		(async () => {
			const response = await API.getAccuracyOverview(startDate, endDate);
			setAccuracy(response);
		})();
	}, [startDate, endDate]);

	return (
		<Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
			<Paper
				sx={{
					display: 'flex',
					flexDirection: 'column',
					mb: 4,
				}}
			>
				<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, pl: 2, pr: 2, pb: 2 }}>
					<Title>
						Accuracy Testing Results for {startDate.toLocaleDateString()} - {endDate.toLocaleDateString()}
					</Title>
					<Button
						size='small'
						variant='outlined'
						sx={{ mt: 'auto', mb: 'auto', ml: 'auto', color: Color.GRAY, borderColor: Color.GRAY, flexShrink: 1 }}
						href={`/staffApp/accuracy/aifi?startDate=${startDate.toISOString().substring(0, 10)}&endDate=${endDate.toISOString().substring(0, 10)}`}
						target='_blank'
					>
						Download
					</Button>
				</Box>
				<Grid container spacing={3} sx={{ ml: 2, mr: 2, mb: 2 }}>
					<Grid item xs={6} md={4} lg={3} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>
						<Group sx={{ fontSize: 30 }} color='action' />
						<Box sx={{ ml: 2 }}>
							<Typography color='text.primary' variant='h6'>
								{accuracy.totalJourneys}
							</Typography>
							<Typography color='text.secondary'>
								Journeys ({accuracy.totalJourneys > 0 ? Math.floor((reviewedJourneyCount / accuracy.totalJourneys) * 100) : 0}% reviewed)
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={6} md={4} lg={3} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>
						<ReceiptLong sx={{ fontSize: 30 }} color='action' />
						<Box sx={{ ml: 2 }}>
							<Typography color='text.primary' variant='h6'>
								{accuracy.totalJourneys > 0
									? Number(((accuracy.totalJourneys - accuracy.journeysWithErrors) / accuracy.totalJourneys) * 100).toFixed(2)
									: 0}
								%
							</Typography>
							<Typography color='text.secondary'>Receipt Accuracy</Typography>
						</Box>
					</Grid>
					<Grid item xs={6} md={4} lg={3} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>
						<Category sx={{ fontSize: 30 }} color='action' />
						<Box sx={{ ml: 2 }}>
							<Typography color='text.primary' variant='h6'>
								{accuracy.totalItems}
							</Typography>
							<Typography color='text.secondary'>Items ({accuracy.itemsWithErrors} errors)</Typography>
						</Box>
					</Grid>

					<Grid item xs={6} md={4} lg={3} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>
						<BakeryDining sx={{ fontSize: 30 }} color='action' />
						<Box sx={{ ml: 2 }}>
							<Typography color='text.primary' variant='h6'>
								{accuracy.totalItems > 0
									? Number(((accuracy.totalItems - accuracy.itemsWithErrors) / accuracy.totalItems) * 100).toFixed(2)
									: 0}
								%
							</Typography>
							<Typography color='text.secondary'>Item Accuracy</Typography>
						</Box>
					</Grid>
				</Grid>
			</Paper>
			<Paper
				sx={{
					p: 2,
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Title>Testing Journeys</Title>
				{accuracy.testingJourneys
					.sort((a, b) => {
						if (a.reviewed && !b.reviewed) return 1;
						if (!a.reviewed && b.reviewed) return -1;
						if (a.reviewed && b.reviewed) {
							if (a.customerId > b.customerId) return -1;
							if (a.customerId < b.customerId) return 1;
						}
						if (!a.reviewed && !b.reviewed) {
							if (a.errorCount > b.errorCount) return -1;
							if (a.errorCount < b.errorCount) return 1;
						}

						return 0;
					})
					.map((journey, index) => {
						return (
							<Paper elevation={3} sx={{ display: 'flex', mt: 2, p: 2 }} key={journey.customerId}>
								<ReceiptLong
									sx={{ fontSize: 30, color: journey.reviewed ? Color.BLUE : Color.ORANGE, mr: 2, mt: 'auto', mb: 'auto', flexShrink: 1 }}
									color='primary'
								/>
								<Box sx={{ display: 'flex', flexDirection: 'column', mr: 1, mt: 'auto', mb: 'auto', flexShrink: 1 }}>
									<Typography sx={{ fontWeight: 'bold', fontSize: 14 }}>
										{journey.itemCount > 0 ? Math.floor(((journey.itemCount - journey.errorCount) / journey.itemCount) * 100) : 100}%
										accurate receipt
									</Typography>
									<Typography sx={{ color: Color.GRAY, fontSize: 13 }}>{new Date(journey.timestamp).toLocaleString()}</Typography>
								</Box>
								<Box sx={{ display: 'flex', flexDirection: 'column', ml: 'auto', mr: 1, mt: 'auto', mb: 'auto', flexShrink: 1 }}>
									<Box sx={{ display: 'flex', flexDirection: 'row' }}>
										<Category sx={{ fontSize: 13, color: Color.BLUE, mr: 1, mt: 'auto', mb: 'auto' }} />
										<Typography sx={{ color: Color.GRAY, fontSize: 13 }}>
											{journey.itemCount} item{journey.itemCount === 1 ? '' : 's'}
										</Typography>
									</Box>
									<Box sx={{ display: 'flex', flexDirection: 'row' }}>
										<ErrorOutline
											sx={{ fontSize: 13, color: journey.errorCount === 0 ? Color.BLUE : Color.RED, mr: 1, mt: 'auto', mb: 'auto' }}
										/>
										<Typography sx={{ color: Color.GRAY, fontSize: 13 }}>
											{journey.errorCount} {journey.reviewed ? '' : 'potential'} error{journey.errorCount === 1 ? '' : 's'}
										</Typography>
									</Box>
								</Box>

								<Button
									size='small'
									variant='outlined'
									sx={{ mt: 'auto', mb: 'auto', ml: 'auto', color: Color.GRAY, borderColor: Color.GRAY, flexShrink: 1 }}
									href={`/accuracy/${journey.customerId}`}
								>
									{journey.reviewed ? 'View Details' : 'Review'}
								</Button>
							</Paper>
						);
					})}
			</Paper>
		</Container>
	);
};

export default AccuracyOverview;

import React, { useLayoutEffect, useRef, useState } from 'react';
import { Box, Paper } from '@mui/material';
import { Color } from '../../assets/Color';
import GateScreenState from '../../models/GateScreenState';
import { useAppSelector } from '../../datalayer/hooks';
import Title from '../General/Title';
import TitleLive from '../General/TitleLive';

const GreenwichGateArea = () => {
	const gateScreenStates: Array<GateScreenState> = useAppSelector((state) => state.store.gateScreenStates);

	const [size, setSize] = useState(0);

	const ref: any = useRef(null);

	useLayoutEffect(() => {
		if (ref.current) {
			setSize(ref.current.offsetWidth);
		}
	}, []);

	React.useEffect(() => {
		function handleResize() {
			if (ref.current) {
				setSize(ref.current.offsetWidth);
			}
		}

		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	let entryGate1BackgroundColor: string = Color.GRAY;
	let entryGate2BackgroundColor: string = Color.GRAY;
	let entryGate3BackgroundColor: string = Color.GRAY;
	let exitGate1BackgroundColor: string = Color.GRAY;
	let exitGate2BackgroundColor: string = Color.GRAY;
	let exitGate3BackgroundColor: string = Color.GRAY;

	let entryGate1TextColor: string = Color.WHITE;
	let entryGate2TextColor: string = Color.WHITE;
	let entryGate3TextColor: string = Color.WHITE;
	let exitGate1TextColor: string = Color.WHITE;
	let exitGate2TextColor: string = Color.WHITE;
	let exitGate3TextColor: string = Color.WHITE;

	gateScreenStates.forEach((gateScreenState) => {
		switch (gateScreenState.gateId) {
			case 'entryGate1':
				entryGate1BackgroundColor = gateScreenState.content.backgroundColor;
				entryGate1TextColor = gateScreenState.content.foregroundColor;
				break;
			case 'entryGate2':
				entryGate2BackgroundColor = gateScreenState.content.backgroundColor;
				entryGate2TextColor = gateScreenState.content.foregroundColor;
				break;
			case 'entryGate3':
				entryGate3BackgroundColor = gateScreenState.content.backgroundColor;
				entryGate3TextColor = gateScreenState.content.foregroundColor;
				break;
			case 'exitGate1':
				exitGate1BackgroundColor = gateScreenState.content.backgroundColor;
				exitGate1TextColor = gateScreenState.content.foregroundColor;
				break;
			case 'exitGate2':
				exitGate2BackgroundColor = gateScreenState.content.backgroundColor;
				exitGate2TextColor = gateScreenState.content.foregroundColor;
				break;
			case 'exitGate3':
				exitGate3BackgroundColor = gateScreenState.content.backgroundColor;
				exitGate3TextColor = gateScreenState.content.foregroundColor;
				break;
		}
	});

	return (
		<Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
			<TitleLive sseRelevant={true} refresh={() => {}} lastRefresh={new Date()}>
				GateScreens
			</TitleLive>
			<Box sx={{ position: 'relative', height: size }} ref={ref}>
				<svg width={size} height={size}>
					<g>
						<rect width={size * 0.2} height={size * 0.275} rx={size * 0.02} transform={`translate(${size * 0.025} ${size * 0.025})`} fill={exitGate3BackgroundColor} />
					</g>
					<g>
						<rect width={size * 0.2} height={size * 0.275} rx={size * 0.02} transform={`translate(${size * 0.25} ${size * 0.025})`} fill={exitGate2BackgroundColor} />
					</g>
					<g>
						<rect width={size * 0.2} height={size * 0.275} rx={size * 0.02} transform={`translate(${size * 0.475} ${size * 0.025})`} fill={exitGate1BackgroundColor} />
					</g>
					<g>
						<rect width={size * 0.2} height={size * 0.275} rx={size * 0.02} transform={`translate(${size * 0.975} ${size * 0.325}) rotate(90)`} fill={entryGate3BackgroundColor} />
					</g>
					<g>
						<rect width={size * 0.2} height={size * 0.275} rx={size * 0.02} transform={`translate(${size * 0.975} ${size * 0.55}) rotate(90)`} fill={entryGate2BackgroundColor} />
					</g>
					<g>
						<rect width={size * 0.2} height={size * 0.275} rx={size * 0.02} transform={`translate(${size * 0.975} ${size * 0.775}) rotate(90)`} fill={entryGate1BackgroundColor} />
					</g>
					<g transform={`translate(${size * -0.45} 0)`}>
						<text transform={`translate(${size * 0.5345} ${size * 0.136})`} fill={exitGate3TextColor} fontSize={size * 0.1} fontWeight='700'>
							<tspan x={`${size * 0.013}`} y={`${size * 0.0775}`}>
								3
							</tspan>
						</text>
						<text transform={`translate(${size * 0.4925} ${size * 0.0925})`} fill={exitGate3TextColor} fontSize={size * 0.025} fontWeight='700'>
							<tspan x={`${size * 0.035}`} y={`${size * 0.0195}`}>
								Exit Gate
							</tspan>
						</text>
					</g>
					<g transform={`translate(${size * -0.225} 0)`}>
						<text transform={`translate(${size * 0.5345} ${size * 0.136})`} fill={exitGate2TextColor} fontSize={size * 0.1} fontWeight='700'>
							<tspan x={`${size * 0.013}`} y={`${size * 0.0775}`}>
								2
							</tspan>
						</text>
						<text transform={`translate(${size * 0.4925} ${size * 0.0925})`} fill={exitGate2TextColor} fontSize={size * 0.025} fontWeight='700'>
							<tspan x={`${size * 0.035}`} y={`${size * 0.0195}`}>
								Exit Gate
							</tspan>
						</text>
					</g>
					<g>
						<text transform={`translate(${size * 0.5345} ${size * 0.136})`} fill={exitGate1TextColor} fontSize={size * 0.1} fontWeight='700'>
							<tspan x={`${size * 0.013}`} y={`${size * 0.0775}`}>
								1
							</tspan>
						</text>
						<text transform={`translate(${size * 0.4925} ${size * 0.0925})`} fill={exitGate1TextColor} fontSize={size * 0.025} fontWeight='700'>
							<tspan x={`${size * 0.035}`} y={`${size * 0.0195}`}>
								Exit Gate
							</tspan>
						</text>
					</g>
					<g transform={`translate(${size * 0.2625} ${size * 0.261})`}>
						<text transform={`translate(${size * 0.5345} ${size * 0.136})`} fill={entryGate3TextColor} fontSize={size * 0.1} fontWeight='700'>
							<tspan x={`${size * 0.013}`} y={`${size * 0.0775}`}>
								3
							</tspan>
						</text>
						<text transform={`translate(${size * 0.4925} ${size * 0.0925})`} fill={entryGate3TextColor} fontSize={size * 0.025} fontWeight='700'>
							<tspan x={`${size * 0.02}`} y={`${size * 0.0195}`}>
								Entry Gate
							</tspan>
						</text>
					</g>
					<g transform={`translate(${size * 0.2625} ${size * 0.486})`}>
						<text transform={`translate(${size * 0.5345} ${size * 0.136})`} fill={entryGate2TextColor} fontSize={size * 0.1} fontWeight='700'>
							<tspan x={`${size * 0.013}`} y={`${size * 0.0775}`}>
								2
							</tspan>
						</text>
						<text transform={`translate(${size * 0.4925} ${size * 0.0925})`} fill={entryGate2TextColor} fontSize={size * 0.025} fontWeight='700'>
							<tspan x={`${size * 0.02}`} y={`${size * 0.0195}`}>
								Entry Gate
							</tspan>
						</text>
					</g>
					<g transform={`translate(${size * 0.2625} ${size * 0.711})`}>
						<text transform={`translate(${size * 0.5345} ${size * 0.136})`} fill={entryGate1TextColor} fontSize={size * 0.1} fontWeight='700'>
							<tspan x={`${size * 0.013}`} y={`${size * 0.0775}`}>
								1
							</tspan>
						</text>
						<text transform={`translate(${size * 0.4925} ${size * 0.0925})`} fill={entryGate1TextColor} fontSize={size * 0.025} fontWeight='700'>
							<tspan x={`${size * 0.02}`} y={`${size * 0.0195}`}>
								Entry Gate
							</tspan>
						</text>
					</g>
				</svg>
			</Box>
		</Paper>
	);
};

export default GreenwichGateArea;

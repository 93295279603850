import { SseContent } from './SseContent';

export interface SseMessage {
	type: SseMessageType;
	content: SseContent | undefined;
}

export enum SseMessageType {
	PING = 'PING',
	CONTENT = 'CONTENT',
	RELOAD = 'RELOAD',
}

import { AiFiCustomerRole } from './AiFiCustomerRole';

export enum AiFiCheckoutZoneEnteredFlag {
	POTENTIALLY_INTERACTED_WITH_ALCOHOL = 'POTENTIALLY_INTERACTED_WITH_ALCOHOL',
	POTENTIALLY_SUSPICIOUS_BEHAVIOUR = 'POTENTIALLY_SUSPICIOUS_BEHAVIOUR',
}

export default class AiFiEventPayload {
	customerId: string;
	sessionId: string;
	role: AiFiCustomerRole;
	flags: Array<AiFiCheckoutZoneEnteredFlag>;
	checkoutZoneId: number | undefined;

	constructor(
		customerId: string,
		sessionId: string,
		role: AiFiCustomerRole,
		flags: Array<AiFiCheckoutZoneEnteredFlag> = [],
		checkoutZoneId: number | undefined = undefined
	) {
		this.customerId = customerId;
		this.sessionId = sessionId;
		this.role = role;
		this.flags = flags;
		this.checkoutZoneId = checkoutZoneId;
	}
}

export enum ReceiptStatus {
	PENDING = 'PENDING',
	COMPLETED = 'COMPLETED',
}

export default interface ReceiptListItemDTO {
	id: number;
	timestamp: Date;
	state: ReceiptStatus;
	itemCount?: number;
	transactionValue?: number;
	currencyCode?: string;
}

import React, { useEffect, useState } from 'react';
import API from '../datalayer/API';
import ManualChapter from '../models/ManualChapter';
import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, TextField, Typography } from '@mui/material';
import { Add, Edit } from '@mui/icons-material';
import { RoleGate } from '../components/General/RoleGate';
import { StaffRole } from '../models/StaffRole';
import Chapter from '../components/Manual/Chapter';

const ManualPage = () => {
	const [chapters, setChapters] = useState<ManualChapter[]>([]);

	const [addChapterDialogOpen, setAddChapterDialogOpen] = useState(false);
	const [newChapterName, setNewChapterName] = useState('');

	const addChapter = async () => {
		if (!newChapterName) return;
		await API.createManualChapter(newChapterName);
		setAddChapterDialogOpen(false);
		await loadManual();
	};

	const loadManual = async () => {
		const chapters = await API.getManual();
		setChapters(chapters);
	};

	useEffect(() => {
		loadManual();
	}, []);

	return (
		<Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
			<Dialog open={addChapterDialogOpen} onClose={() => setAddChapterDialogOpen(false)}>
				<DialogTitle>Add Chapter</DialogTitle>
				<DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
					<DialogContentText>Enter the name of the new chapter.</DialogContentText>
					<TextField
						autoFocus
						margin='dense'
						value={newChapterName}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							setNewChapterName(event.target.value);
						}}
						id='name'
						label='Chapter Name'
						fullWidth
						variant='standard'
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={addChapter}>Create</Button>
				</DialogActions>
			</Dialog>
			<Paper
				sx={{
					display: 'flex',
					flexDirection: 'column',
					mb: 4,
				}}
			>
				<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, pl: 2, pr: 2, pb: 2 }}>
					<Typography variant='h6'>Store Manual</Typography>
					<RoleGate requiredRole={StaffRole.ROOT}>
						<Button
							sx={{ ml: 'auto' }}
							variant='outlined'
							color='primary'
							startIcon={<Add />}
							onClick={() => {
								setAddChapterDialogOpen(true);
							}}
						>
							Create Chapter
						</Button>
					</RoleGate>
				</Box>
			</Paper>
			{chapters.map((chapter) => (
				<Chapter chapter={chapter} reload={loadManual} />
			))}
		</Container>
	);
};

export default ManualPage;

import * as React from 'react';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import {
	QrCodeScanner,
	Check,
	ErrorOutline,
	PersonAddAlt1,
	PersonRemove,
	ShoppingCart,
	DoorSlidingOutlined,
	Liquor,
	CenterFocusWeak,
} from '@mui/icons-material';
import { Color } from '../../assets/Color';
import ScanEvent from '../../models/ScanEvent';
import AiFiEvent from '../../models/AiFiEvent';
import { EventType } from '../../models/EventType';
import Box from '@mui/material/Box';
import CheckinEvent from '../../models/CheckinEvent';
import { CheckinEventType } from '../../models/CheckinEventPayload';
import { AiFiCheckoutZoneEnteredFlag } from '../../models/AiFiEventPayload';
import TapAppInEvent from '../../models/TapAppInEvent';

const LegacyEventItem: React.FC<{ event: ScanEvent | AiFiEvent | CheckinEvent | TapAppInEvent; displayButton: boolean; displayFullDate: boolean }> = ({
	event,
	displayButton,
	displayFullDate,
}) => {
	let link = '/journey';
	if (event.type === EventType.SCAN && (event as ScanEvent).body.gateId.startsWith('entry')) {
		link += `?verificationCode=${(event as ScanEvent).body.code}`;
	} else if (event.type === EventType.SCAN && (event as ScanEvent).body.gateId.startsWith('exit')) {
		link += `?exitCode=${(event as ScanEvent).body.code}`;
	} else if (event.type === EventType.CHECKIN && (event as CheckinEvent).body.result !== CheckinEventType.ERROR) {
		link += `?customerId=${(event as CheckinEvent).body.customerId}`;
	} else if (event.type === EventType.CHECKIN && (event as CheckinEvent).body.result === CheckinEventType.ERROR) {
		link += `s?sub=${(event as CheckinEvent).body.sub}`;
	} else {
		link += `?aifiSessionId=${(event as AiFiEvent).body.sessionId}`;
	}

	return (
		<Card variant='outlined' sx={{ display: 'flex', mt: 2 }}>
			<CardContent sx={{ display: 'flex', mt: 'auto', mb: 'auto', minWidth: 150 }}>
				{event.type === EventType.CHECKIN && (event as CheckinEvent).body.result !== CheckinEventType.ERROR && (
					<>
						<QrCodeScanner sx={{ fontSize: 30, color: Color.BLUE, mr: 2 }} color='primary' />
					</>
				)}
				{event.type === EventType.CHECKIN && (event as CheckinEvent).body.result === CheckinEventType.ERROR && (
					<>
						<QrCodeScanner sx={{ fontSize: 30, color: Color.RED, mr: 2 }} color='primary' />
					</>
				)}
				{event.type === EventType.SCAN && (event as ScanEvent).body.success && (
					<>
						<CenterFocusWeak sx={{ fontSize: 30, color: Color.GREEN, mr: 2 }} color='primary' />
						<Check sx={{ fontSize: 30, color: Color.GREEN, mr: 2 }} />
					</>
				)}
				{event.type === EventType.SCAN && !(event as ScanEvent).body.success && (
					<>
						<CenterFocusWeak sx={{ fontSize: 30, color: Color.RED, mr: 2 }} />
						<ErrorOutline sx={{ fontSize: 30, color: Color.RED, mr: 2 }} />
					</>
				)}
				{event.type === EventType.ENTERED && (
					<>
						<PersonAddAlt1 sx={{ fontSize: 30, color: Color.BLUE, mr: 2 }} />
					</>
				)}
				{event.type === EventType.CHECKOUT_ZONE_ENTERED && (
					<>
						<DoorSlidingOutlined sx={{ fontSize: 30, color: Color.BLUE, mr: 2 }} />
					</>
				)}
				{event.type === EventType.CHECKOUT_ZONE_ENTERED &&
					(event as AiFiEvent).body.flags.includes(AiFiCheckoutZoneEnteredFlag.POTENTIALLY_INTERACTED_WITH_ALCOHOL) && (
						<>
							<Liquor sx={{ fontSize: 30, color: Color.BLUE, mr: 2 }} />
						</>
					)}
				{event.type === EventType.LEFT && (
					<>
						<PersonRemove sx={{ fontSize: 30, color: Color.BLUE, mr: 2 }} />
					</>
				)}
				{event.type === EventType.CHECKOUT && (
					<>
						<ShoppingCart sx={{ fontSize: 30, color: Color.BLUE, mr: 2 }} />
					</>
				)}
			</CardContent>
			<CardContent sx={{ display: 'flex', flex: 1, justifyContent: 'space-between', flexWrap: 'wrap' }}>
				<Typography sx={{ fontSize: 14, mt: 'auto', mb: 'auto' }} color='text.secondary' gutterBottom>
					{displayFullDate ? new Date(event.timestamp).toLocaleString() : new Date(event.timestamp).toLocaleTimeString()}
				</Typography>
				{event.type === EventType.CHECKIN && (event as CheckinEvent).body.result === CheckinEventType.OK && (
					<Typography sx={{ fontSize: 14, mt: 'auto', mb: 'auto' }} color='text.primary' gutterBottom>
						QR code generated for journey {(event as CheckinEvent).body.customerId}
					</Typography>
				)}
				{event.type === EventType.CHECKIN && (event as CheckinEvent).body.result === CheckinEventType.REUSE && (
					<Typography sx={{ fontSize: 14, mt: 'auto', mb: 'auto' }} color='text.primary' gutterBottom>
						QR code reopened for journey {(event as CheckinEvent).body.customerId}
					</Typography>
				)}
				{event.type === EventType.CHECKIN && (event as CheckinEvent).body.result === CheckinEventType.ERROR && (
					<Typography sx={{ fontSize: 14, mt: 'auto', mb: 'auto' }} color='text.primary' gutterBottom>
						QR code could not be generated for customer {JSON.parse((event as CheckinEvent).body.sub).customer_reference}:{' '}
						{(event as CheckinEvent).body.errorMessage}
					</Typography>
				)}
				{event.type === EventType.SCAN && (event as ScanEvent).body.success && (
					<Typography sx={{ fontSize: 14, mt: 'auto', mb: 'auto' }} color='text.primary' gutterBottom>
						Successful scan on {(event as ScanEvent).body.scannerPosition} of{' '}
						{(event as ScanEvent).body.gateId.startsWith('entry') ? 'entry' : 'exit'} gate{' '}
						{(event as ScanEvent).body.gateId.substring((event as ScanEvent).body.gateId.length - 1)}: {(event as ScanEvent).body.code}
					</Typography>
				)}
				{event.type === EventType.SCAN && !(event as ScanEvent).body.success && (
					<Box sx={{ display: 'flex', flexDirection: 'column' }}>
						<Typography sx={{ fontSize: 14, mt: 'auto', mb: 'auto' }} color='text.primary' gutterBottom>
							Failed scan on {(event as ScanEvent).body.scannerPosition} of{' '}
							{(event as ScanEvent).body.gateId.startsWith('entry') ? 'entry' : 'exit'} gate{' '}
							{(event as ScanEvent).body.gateId.substring((event as ScanEvent).body.gateId.length - 1)}: {(event as ScanEvent).body.code}
						</Typography>
						<Typography sx={{ fontSize: 14, mt: 'auto', mb: 'auto' }} color='text.primary' gutterBottom>
							{(event as ScanEvent).body.errorMessage}
						</Typography>
					</Box>
				)}
				{event.type === EventType.ENTERED && (
					<Typography sx={{ fontSize: 14, mt: 'auto', mb: 'auto' }} color='text.primary' gutterBottom>
						Customer entered on AiFi journey {(event as AiFiEvent).body.customerId}
					</Typography>
				)}
				{event.type === EventType.CHECKOUT_ZONE_ENTERED &&
					!(event as AiFiEvent).body.flags.includes(AiFiCheckoutZoneEnteredFlag.POTENTIALLY_INTERACTED_WITH_ALCOHOL) && (
						<Typography sx={{ fontSize: 14, mt: 'auto', mb: 'auto' }} color='text.primary' gutterBottom>
							Customer on AiFi journey {(event as AiFiEvent).body.customerId} entered checkout zone {(event as AiFiEvent).body.checkoutZoneId}
						</Typography>
					)}
				{event.type === EventType.CHECKOUT_ZONE_ENTERED &&
					(event as AiFiEvent).body.flags.includes(AiFiCheckoutZoneEnteredFlag.POTENTIALLY_INTERACTED_WITH_ALCOHOL) && (
						<Typography sx={{ fontSize: 14, mt: 'auto', mb: 'auto' }} color='text.primary' gutterBottom>
							Customer on AiFi journey {(event as AiFiEvent).body.customerId} entered checkout zone {(event as AiFiEvent).body.checkoutZoneId}{' '}
							with age restricted items detected
						</Typography>
					)}
				{event.type === EventType.LEFT && (
					<Typography sx={{ fontSize: 14, mt: 'auto', mb: 'auto' }} color='text.primary' gutterBottom>
						Customer left on AiFi journey {(event as AiFiEvent).body.customerId}
					</Typography>
				)}
				{event.type === EventType.CHECKOUT && (
					<Typography sx={{ fontSize: 14, mt: 'auto', mb: 'auto' }} color='text.primary' gutterBottom>
						Basket received for AiFi journey {(event as AiFiEvent).body.customerId}
					</Typography>
				)}
				{displayButton && (
					<Button size='small' sx={{ mt: 'auto', mb: 'auto' }} href={link}>
						Details
					</Button>
				)}
			</CardContent>
		</Card>
	);
};

export default LegacyEventItem;

import React from 'react';
import { RoleGate } from '../components/General/RoleGate';
import { StaffRole } from '../models/StaffRole';
import IssueReportDownloadSelector from '../components/IssueReport/IssueReportDownloadSelector';
import IssueReportForm from '../components/IssueReport/IssueReportForm';

const IssueReportPage = () => {
	return (
		<>
			<IssueReportForm />
			<RoleGate requiredRole={StaffRole.ROOT}>
				<IssueReportDownloadSelector />
			</RoleGate>
		</>
	);
};

export default IssueReportPage;

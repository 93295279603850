import * as React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Dangerous, DoorSlidingOutlined, Liquor, PersonAddAlt1, PersonRemove, ShoppingCart, Videocam, WarningAmber } from '@mui/icons-material';
import { Color } from '../../assets/Color';
import Box from '@mui/material/Box';
import { Paper } from '@mui/material';
import AiFiEvent from '../../models/AiFiEvent';
import { EventType } from '../../models/EventType';
import { AiFiCheckoutZoneEnteredFlag } from '../../models/AiFiEventPayload';

const AiFiEventItem: React.FC<{ event: AiFiEvent; displayButton: boolean; displayFullDate: boolean }> = ({ event, displayButton, displayFullDate }) => {
	const link = `/journey?aifiSessionId=${(event as AiFiEvent).body.sessionId}`;

	let icon = <></>;
	let title = '';

	switch (event.type) {
		case EventType.ENTERED:
			icon = <PersonAddAlt1 sx={{ fontSize: 30, color: Color.BLUE, mr: 2, mt: 'auto', mb: 'auto', flexShrink: 1 }} color='primary' />;
			title = 'CUSTOMER ENTERED';
			break;
		case EventType.CHECKOUT_ZONE_ENTERED:
			if (event.body.flags.includes(AiFiCheckoutZoneEnteredFlag.POTENTIALLY_INTERACTED_WITH_ALCOHOL)) {
				icon = (
					<Box sx={{ display: 'grid', mt: 'auto', mb: 'auto', flexShrink: 1 }}>
						<DoorSlidingOutlined sx={{ fontSize: 30, color: Color.LIGHT_GRAY, mr: 2, gridColumn: 1, gridRow: 1 }} color='primary' />
						<Dangerous sx={{ fontSize: 20, color: Color.RED, mr: 2, gridColumn: 1, gridRow: 1, marginLeft: '0.333rem', marginTop: '0.333rem' }} />
					</Box>
				);
			} else {
				icon = <DoorSlidingOutlined sx={{ fontSize: 30, color: Color.BLUE, mr: 2, mt: 'auto', mb: 'auto', flexShrink: 1 }} color='primary' />;
			}

			title = 'CUSTOMER APPROACHED EXIT GATE';
			break;
		case EventType.LEFT:
			icon = <PersonRemove sx={{ fontSize: 30, color: Color.BLUE, mr: 2, mt: 'auto', mb: 'auto', flexShrink: 1 }} color='primary' />;
			title = 'CUSTOMER LEFT';
			break;
		case EventType.CHECKOUT:
			icon = <ShoppingCart sx={{ fontSize: 30, color: Color.BLUE, mr: 2, mt: 'auto', mb: 'auto', flexShrink: 1 }} color='primary' />;
			title = 'AIFI BASKET RECEIVED';
			break;
	}

	return (
		<Paper elevation={3} sx={{ display: 'flex', mt: 2, p: 2 }}>
			{icon}

			<Box sx={{ display: 'flex', flexDirection: 'column', mr: 1, mt: 'auto', mb: 'auto', flexShrink: 1 }}>
				<Typography sx={{ fontWeight: 'bold', fontSize: 14 }}>{title}</Typography>
				<Typography sx={{ color: Color.GRAY, fontSize: 13 }}>
					{displayFullDate ? new Date(event.timestamp).toLocaleString() : new Date(event.timestamp).toLocaleTimeString()}
				</Typography>
			</Box>

			<Box sx={{ display: 'flex', flexDirection: 'column', ml: 'auto', mr: 1, mt: 'auto', mb: 'auto', flexShrink: 1 }}>
				<Box sx={{ display: 'flex', flexDirection: 'row' }}>
					<Videocam sx={{ fontSize: 13, color: Color.BLUE, mr: 1, mt: 'auto', mb: 'auto' }} />
					<Typography sx={{ color: Color.GRAY, fontSize: 13 }}>AiFi Session ID {event.body.sessionId}</Typography>
				</Box>
				{event.type === EventType.CHECKOUT_ZONE_ENTERED &&
					event.body.flags.includes(AiFiCheckoutZoneEnteredFlag.POTENTIALLY_INTERACTED_WITH_ALCOHOL) && (
						<Box sx={{ display: 'flex', flexDirection: 'row' }}>
							<Liquor sx={{ fontSize: 13, color: Color.BLUE, mr: 1, mt: 'auto', mb: 'auto' }} />
							<Typography sx={{ color: Color.GRAY, fontSize: 13 }}>Age restricted items detected</Typography>
						</Box>
					)}
				{event.type === EventType.CHECKOUT_ZONE_ENTERED && event.body.flags.includes(AiFiCheckoutZoneEnteredFlag.POTENTIALLY_SUSPICIOUS_BEHAVIOUR) && (
					<Box sx={{ display: 'flex', flexDirection: 'row' }}>
						<WarningAmber sx={{ fontSize: 13, color: Color.BLUE, mr: 1, mt: 'auto', mb: 'auto' }} />
						<Typography sx={{ color: Color.GRAY, fontSize: 13 }}>Suspicious customer behaviour detected</Typography>
					</Box>
				)}
			</Box>

			{displayButton && (
				<Button
					size='small'
					variant='outlined'
					sx={{ mt: 'auto', mb: 'auto', ml: 'auto', color: Color.GRAY, borderColor: Color.GRAY, flexShrink: 1 }}
					target='_blank'
					href={link}
				>
					View Journey
				</Button>
			)}
		</Paper>
	);
};

export default AiFiEventItem;

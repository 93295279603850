export default class CheckinEventPayload {
	customerId: number;
	sub: string;
	appVersion: string | undefined;
	experimental: boolean;
	sprykerResult: SprykerCustomerCheckResult | undefined;
	result: CheckinEventType;
	errorMessage: string | undefined;

	constructor(
		customerId: number,
		sub: string,
		appVersion: string | undefined,
		experimental: boolean,
		preAuthResult: SprykerCustomerCheckResult | undefined,
		result: CheckinEventType,
		errorMessage: string | undefined
	) {
		this.customerId = customerId;
		this.sub = sub;
		this.appVersion = appVersion;
		this.experimental = experimental;
		this.sprykerResult = preAuthResult;
		this.result = result;
		this.errorMessage = errorMessage;
	}
}

export enum CheckinEventType {
	OK = 'OK',
	REUSE = 'REUSE',
	ERROR = 'ERROR',
}

export class PreAuthConfiguration {
	performPreAuth: boolean;
	preAuthAmount: number;
	ignorePreAuthResult: boolean;

	secondaryPreAuthEnabled: boolean;
	secondaryPreAuthAmount: number;
	ignoreSecondaryPreAuthResult: boolean;

	skipForUsersWithMinOrderCount: number;
	skipOnSeamlessPaymentsForMinOrderCount: number;

	constructor(
		performPreAuth: boolean,
		preAuthAmount: number,
		ignorePreAuthResult: boolean,

		secondaryPreAuthEnabled: boolean,
		secondaryPreAuthAmount: number,
		ignoreSecondaryPreAuthResult: boolean,

		skipForUsersWithMinOrderCount: number,
		skipOnSeamlessPaymentsForMinOrderCount: number
	) {
		this.performPreAuth = performPreAuth;
		this.preAuthAmount = preAuthAmount;
		this.ignorePreAuthResult = ignorePreAuthResult;

		this.secondaryPreAuthEnabled = secondaryPreAuthEnabled;
		this.secondaryPreAuthAmount = secondaryPreAuthAmount;
		this.ignoreSecondaryPreAuthResult = ignoreSecondaryPreAuthResult;

		this.skipForUsersWithMinOrderCount = skipForUsersWithMinOrderCount;
		this.skipOnSeamlessPaymentsForMinOrderCount = skipOnSeamlessPaymentsForMinOrderCount;
	}
}

export class SprykerCustomerCheckResult {
	type: SprykerCustomerCheckResultType;
	preAuthAmount: number | null;
	isPriorityCustomer: boolean;
	preAuthPerformed: boolean;
	preAuthSuccessful: boolean;

	constructor(sprykerCustomerCheckResultType: SprykerCustomerCheckResultType, preAuthAmount: number | null = null, isPriorityCustomer: boolean = false, preAuthSuccessful: boolean = false) {
		this.type = sprykerCustomerCheckResultType;
		this.preAuthAmount = preAuthAmount;
		this.isPriorityCustomer = isPriorityCustomer;
		this.preAuthPerformed = preAuthAmount !== null;
		this.preAuthSuccessful = preAuthSuccessful;
	}
}

export enum SprykerCustomerCheckResultType {
	REQUEST_FAILED = 'REQUEST_FAILED',

	DECLINED_PAYMENT_OUT_OF_RETRIES = 'DECLINED_PAYMENT_OUT_OF_RETRIES',
	DECLINED_PAYMENT = 'DECLINED_PAYMENT',
	TOO_MANY_OPEN_RECEIPTS = 'TOO_MANY_OPEN_RECEIPTS',

	NO_CREDIT_CARD = 'NO_CREDIT_CARD',
	EXPIRED_CREDIT_CARD = 'EXPIRED_CREDIT_CARD',
	LOCKED_CREDIT_CARD = 'LOCKED_CREDIT_CARD',
	SOFT_DECLINED_CREDIT_CARD = 'SOFT_DECLINED_CREDIT_CARD',

	FAILED_PRE_AUTH = 'FAILED_PRE_AUTH',

	NO_ACTIVE_CREDIT_CARD = 'NO_ACTIVE_CREDIT_CARD',
	USER_LOCKED = 'USER_LOCKED',

	OK = 'OK',
}

import React, { useLayoutEffect, useRef, useState } from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { MenuListItems } from './MenuListItems';
import { Color } from './assets/Color';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { LinkProps } from '@mui/material/Link';
import { Outlet } from 'react-router-dom';
import { Alert, AppBar, Drawer, Menu, MenuItem, Snackbar } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import { useAppDispatch } from './datalayer/hooks';
import { deleteStore } from './datalayer/Actions';
import useEventStream from './datalayer/useEventStream';
import API from './datalayer/API';
import ScrollEventEmitter from './datalayer/ScrollEventEmitter';

const drawerWidth: number = 240;

const LinkBehavior = React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }>((props, ref) => {
	const { href, ...other } = props;
	// Map href (MUI) -> to (react-router)
	return <RouterLink ref={ref} to={href} {...other} />;
});

const mode: 'dark' | 'light' = 'light';

const mdTheme = createTheme({
	palette: {
		mode,
		primary: {
			//@ts-ignore
			main: mode === 'light' ? Color.DARK_BLUE : Color.WHITE,
		},
		secondary: {
			//@ts-ignore
			main: mode === 'light' ? Color.BLUE : Color.WHITE,
		},
	},
	components: {
		MuiLink: {
			defaultProps: {
				component: LinkBehavior,
			} as LinkProps,
		},
		MuiButtonBase: {
			defaultProps: {
				LinkComponent: LinkBehavior,
			},
		},
	},
});

interface Props {
	/**
	 * Injected by the documentation to work in an iframe.
	 * You won't need it on your project.
	 */
	window?: () => Window;
}

export default function DashboardFrame(props: Props) {
	const dispatch = useAppDispatch();
	useEventStream();

	const [snack, setSnack] = useState<{ message: string; severity: 'success' | 'error' | 'info' | 'warning' } | null>(null);

	const { window } = props;
	const [mobileOpen, setMobileOpen] = React.useState(false);

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const container = window !== undefined ? () => window().document.body : undefined;

	const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);

	const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchor(event.currentTarget);
	};

	const handleClose = () => {
		setAnchor(null);
	};

	const signOut = async () => {
		try {
			await API.logout();
			dispatch(deleteStore());
		} catch (e) {
			console.error(e);
			setSnack({ message: 'Logging out was not successful, please try again', severity: 'error' });
		} finally {
			setTimeout(() => {
				setSnack(null);
			}, 5000);
		}
	};

	const main = useRef<any>(null);

	useLayoutEffect(() => {
		const component = main.current;
		if (!component) return;
		const scrollListener = () => {
			const bottom = component.scrollHeight - component.clientHeight;
			if (component.scrollTop > bottom * 0.8) {
				ScrollEventEmitter.emit('bottom');
			}
		};
		component.addEventListener('scroll', scrollListener);
		return () => {
			component.removeEventListener('scroll', scrollListener);
		};
	}, [main]);

	return (
		<ThemeProvider theme={mdTheme}>
			{snack ? (
				<Snackbar open={true} autoHideDuration={1000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
					<Alert severity={snack.severity} sx={{ width: '100%' }}>
						{snack.message}
					</Alert>
				</Snackbar>
			) : null}
			<Box sx={{ display: 'flex' }}>
				<CssBaseline />
				<AppBar
					position='fixed'
					sx={{
						zIndex: (theme) => theme.zIndex.drawer + 1,
					}}
				>
					<Toolbar>
						<IconButton color='inherit' aria-label='open drawer' edge='start' onClick={handleDrawerToggle} sx={{ mr: 2, display: { sm: 'none' } }}>
							<MenuIcon />
						</IconButton>
						<Typography variant='h6' noWrap component='div' sx={{ flexGrow: 1 }}>
							ALDI SHOP&GO Dashboard
						</Typography>
						<div>
							<IconButton
								size='large'
								aria-label='account of current user'
								aria-controls='menu-appbar'
								aria-haspopup='true'
								onClick={handleMenu}
								color='inherit'
							>
								<AccountCircle />
							</IconButton>
							<Menu
								id='menu-appbar'
								anchorEl={anchor}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								open={Boolean(anchor)}
								onClose={handleClose}
							>
								<MenuItem onClick={signOut}>Logout</MenuItem>
							</Menu>
						</div>
					</Toolbar>
				</AppBar>
				<Box component='nav' sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}>
					<Drawer
						container={container}
						variant='temporary'
						open={mobileOpen}
						onClose={handleDrawerToggle}
						ModalProps={{
							keepMounted: true,
						}}
						sx={{
							display: { xs: 'block', sm: 'none' },
							'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
						}}
					>
						<Toolbar />
						<Divider />
						<List>{MenuListItems}</List>
					</Drawer>
					<Drawer
						variant='permanent'
						sx={{
							display: { xs: 'none', sm: 'block' },
							'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
						}}
						open
					>
						<Toolbar />
						<Divider />
						<List>{MenuListItems}</List>
					</Drawer>
				</Box>
				<Box
					component='main'
					ref={main}
					sx={{
						backgroundColor: (theme) => (theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900]),
						flexGrow: 1,
						height: '100vh',
						overflow: 'auto',
					}}
				>
					<Toolbar />
					<Outlet />
				</Box>
			</Box>
		</ThemeProvider>
	);
}

import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AccuracySelector from '../components/Accuracy/AccuracySelector';
import AccuracyOverview from '../components/Accuracy/AccuracyOverview';

function useQuery() {
	const { search } = useLocation();

	return React.useMemo(() => new URLSearchParams(search), [search]);
}

const AccuracyPage = () => {
	const query = useQuery();

	const startDate = query.get('startDate') ? new Date(query.get('startDate')!) : null;
	const endDate = query.get('endDate') ? new Date(query.get('endDate')!) : null;

	if (!startDate || !endDate) return <AccuracySelector />;

	return <AccuracyOverview startDate={startDate} endDate={endDate} />;
};

export default AccuracyPage;

import { Step, StepLabel, Stepper, styled } from '@mui/material';
import React from 'react';
import { StepIconProps } from '@mui/material/StepIcon';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { Color } from '../../assets/Color';

const CustomProgressBar = ({ activeState, stateMillis, states }: { activeState: string; states: Array<{ state: string; label: string; sublabel: string; icon: any }>; stateMillis: any }) => {
	const StepIconComponent = (props: StepIconProps) => {
		const { active, completed, className } = props;

		const icons: { [index: string]: React.ReactElement } = {};
		states.forEach((state, index) => {
			icons[index + 1] = <state.icon />;
		});

		return (
			<ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
				{icons[String(props.icon)]}
			</ColorlibStepIconRoot>
		);
	};

	return (
		<Stepper activeStep={states.findIndex((item) => item.state === activeState)} alternativeLabel connector={<ColorlibConnector />}>
			{states.map((state) => (
				<Step key={state.state}>
					<StepLabel StepIconComponent={StepIconComponent}>
						<span style={{ fontSize: '1rem' }}>{state.label}</span>
						<br />
						{stateMillis[state.state] && <span style={{ fontSize: '0.8rem' }}>{state.sublabel}</span>}
						<br />
						{stateMillis[state.state] && <span style={{ fontSize: '0.8rem' }}>{new Date(stateMillis[state.state]).toLocaleString()}</span>}
					</StepLabel>
				</Step>
			))}
		</Stepper>
	);
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: 22,
	},
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			backgroundColor: Color.BLUE,
		},
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			backgroundColor: Color.BLUE,
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		height: 3,
		border: 0,
		backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
		borderRadius: 1,
	},
}));

const ColorlibStepIconRoot = styled('div')<{
	ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
	zIndex: 1,
	color: '#fff',
	width: 50,
	height: 50,
	display: 'flex',
	borderRadius: '50%',
	justifyContent: 'center',
	alignItems: 'center',
	...(ownerState.active && {
		backgroundColor: Color.BLUE,
		boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
	}),
	...(ownerState.completed && {
		backgroundColor: Color.BLUE,
	}),
}));

export default CustomProgressBar;

import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { CartItemWithProductCode } from '../../models/AccuracyTestingModels';
import { Add, Delete, Remove } from '@mui/icons-material';

const ReceiptItem = ({
	item,
	removeButtonEnabled,
	onRemove,
	addButtonEnabled,
	onAdd,
	deleteButtonEnabled,
	onDelete,
}: {
	item: CartItemWithProductCode;
	removeButtonEnabled?: boolean;
	onRemove?: () => void;
	addButtonEnabled?: boolean;
	onAdd?: () => void;
	deleteButtonEnabled?: boolean;
	onDelete?: () => void;
}) => {
	return (
		<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start', height: '40px' }}>
			<Typography variant='body2' gutterBottom>
				{item.amount}x {item.name} ({item.code} / {item.productCode})
			</Typography>
			<Box sx={{ marginLeft: 'auto' }}>
				{deleteButtonEnabled && (
					<Button variant='text' onClick={onDelete} sx={{ ml: 'auto' }}>
						<Delete />
					</Button>
				)}
				{removeButtonEnabled && (
					<Button variant='text' onClick={onRemove} sx={{ ml: 'auto' }}>
						<Remove />
					</Button>
				)}
				{addButtonEnabled && (
					<Button variant='text' onClick={onAdd} sx={{ ml: 'auto' }}>
						<Add />
					</Button>
				)}
			</Box>
		</Box>
	);
};

export default ReceiptItem;

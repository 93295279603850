export enum SimulatedEventType {
	APP_IN = 'APP_IN',
	ENTRY_SCAN = 'ENTRY_SCAN',
	EXIT_SCAN = 'EXIT_SCAN',
	AIFI_ENTERED = 'AIFI_ENTERED',
	AIFI_CHECKOUT_ZONE_ENTERED = 'AIFI_CHECKOUT_ZONE_ENTERED',
	AIFI_CHECKOUT_ZONE_ENTERED_WITH_RESTRICTED_ITEMS = 'AIFI_CHECKOUT_ZONE_ENTERED_WITH_RESTRICTED_ITEMS',
	AIFI_CHECKOUT_ZONE_LEFT = 'AIFI_CHECKOUT_ZONE_LEFT',
	AIFI_LEFT = 'AIFI_LEFT',
	AIFI_CHECKOUT_EMPTY = 'AIFI_CHECKOUT_EMPTY',
	AIFI_CHECKOUT_S = 'AIFI_CHECKOUT_S',
	AIFI_CHECKOUT_M = 'AIFI_CHECKOUT_M',
	AIFI_CHECKOUT_L = 'AIFI_CHECKOUT_L',
	AIFI_CHECKOUT_FROM_LIVE_EVENT = 'AIFI_CHECKOUT_FROM_LIVE_EVENT',
}

import { DELETE_STORE, SET_ROLES, SET_SIGNED_IN, SET_SSE_ALLOWED, SET_SSE_CONNECTED, SET_TOKEN_EXPIRY_DATE } from './Types';
import Status from '../models/Status';

const INITIAL_STATE: Status = new Status(false, new Date().toISOString(), [], false, false);

export default function StatusReducer(state = INITIAL_STATE, action: { type: string; payload: any }) {
	switch (action.type) {
		case SET_SIGNED_IN:
			return { ...state, isSignedIn: action.payload };
		case SET_TOKEN_EXPIRY_DATE:
			return { ...state, accessTokenExpiryDate: action.payload };
		case SET_ROLES:
			return { ...state, roles: action.payload };
		case SET_SSE_CONNECTED:
			return { ...state, sseConnected: action.payload };
		case SET_SSE_ALLOWED:
			return { ...state, sseAllowed: action.payload };
		case DELETE_STORE:
			return INITIAL_STATE;
		default:
			return state;
	}
}

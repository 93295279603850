import GateScanner from '../models/GateScanner';
import GateScreenState from '../models/GateScreenState';
import {
	DELETE_STORE,
	SET_CURRENT_CUSTOMER_AMOUNT,
	SET_GATE_SCANNERS,
	SET_GATE_SCREEN_STATES,
	SET_MAX_CUSTOMER_AMOUNT,
	UPDATE_GATE_SCREEN_STATES,
} from './Types';

const INITIAL_STATE: {
	scanners: Array<GateScanner>;
	gateScreenStates: Array<GateScreenState>;
	currentCustomerAmount: number;
	maxCustomerAmount: number | null;
} = { scanners: [], gateScreenStates: [], currentCustomerAmount: 0, maxCustomerAmount: null };

export default function StoreReducer(state = INITIAL_STATE, action: { type: string; payload: any }) {
	switch (action.type) {
		case SET_GATE_SCANNERS:
			return { ...state, scanners: action.payload };
		case SET_GATE_SCREEN_STATES:
			return { ...state, gateScreenStates: action.payload };
		case UPDATE_GATE_SCREEN_STATES:
			const map = new Map();
			//add previous state
			state.gateScreenStates.forEach((gateScreenState: GateScreenState) => {
				map.set(gateScreenState.gateId, gateScreenState);
			});

			//update with new state
			(action.payload as Array<GateScreenState>).forEach((gateScreenState: GateScreenState) => {
				map.set(gateScreenState.gateId, gateScreenState);
			});

			//return
			const gateScreenStates: Array<GateScreenState> = Array.from(map.values());
			return { ...state, gateScreenStates };
		case SET_CURRENT_CUSTOMER_AMOUNT:
			return { ...state, currentCustomerAmount: action.payload };
		case SET_MAX_CUSTOMER_AMOUNT:
			return { ...state, maxCustomerAmount: action.payload };
		case DELETE_STORE:
			return INITIAL_STATE;
		default:
			return state;
	}
}

export enum Color {
	WHITE = '#ffffff',
	BLACK = '#000000',
	LIGHT_GRAY = '#a0a0a0',
	GRAY = '#333333',
	DARK_GRAY = '#111111',
	BLUE = '#00b4de',
	DARK_BLUE = '#00005f',
	RED = '#d70000',
	ORANGE = '#ff7800',
	YELLOW = '#ffc800',
	GREEN = '#66b22e',
}

import { AccountCircle, Add, CloudUpload, Delete, FormatListBulleted, ReceiptLong, Videocam } from '@mui/icons-material';
import {
	Alert,
	Box,
	Button,
	Container,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Grid,
	Paper,
	Snackbar,
	Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
	AccuracyTestingJourneyInterface,
	AccuracyTestingManualReview,
	AccuracyTestingManualReviewType,
	CartItemWithProductCode,
} from '../models/AccuracyTestingModels';
import API from '../datalayer/API';
import ReceiptItem from '../components/Accuracy/ReceiptItem';

function useQuery() {
	const { search } = useLocation();

	return React.useMemo(() => new URLSearchParams(search), [search]);
}

function getUniqueItemIdentifier(item: CartItemWithProductCode | undefined) {
	if (!item) return null;
	if (item.overrideUniqueProductIdentifier) {
		return item.overrideUniqueProductIdentifier;
	}
	return `${item.productCode}_${item.code}_${item.amount}_${item.name}`;
}

enum ErrorType {
	OVERCHARGED = 'OVERCHARGED',
	UNDERCHARGED = 'UNDERCHARGED',
	MISIDENTIFIED = 'MISIDENTIFIED',
}

const AccuracyDetailPage = () => {
	const query = useQuery();
	const params = useParams();

	const navigate = useNavigate();

	const [reviewDialogOpen, setReviewDialogOpen] = useState<boolean>(false);
	const [reviewDialogState, setReviewDialogState] = useState<'aifi' | 'scanned' | 'type'>('aifi');

	const [addScannedItemDialogOpen, setAddScannedItemDialogOpen] = useState<boolean>(false);

	const [submitPossible, setSubmitPossible] = useState<boolean>(false);

	const [journey, setJourney] = useState<AccuracyTestingJourneyInterface | null>(null);
	const [errorCount, setErrorCount] = useState<number>(0);

	const [manualReviews, setManualReviews] = useState<AccuracyTestingManualReview[]>([]);
	const [manualScannedItems, setManualScannedItems] = useState<CartItemWithProductCode[]>([]);
	const [scannedItemsEdited, setScannedItemsEdited] = useState<boolean>(false);

	const [selectedAifiItem, setSelectedAifiItem] = useState<CartItemWithProductCode | undefined>(undefined);
	const [selectedScannedItem, setSelectedScannedItem] = useState<CartItemWithProductCode | undefined>(undefined);

	const [errors, setErrors] = useState<
		Array<{
			type: ErrorType;
			overchargedProduct: CartItemWithProductCode | undefined;
			underchargedProduct: CartItemWithProductCode | undefined;
			errorCount: number;
			message: string;
		}>
	>([]);

	const overchargedErrors = errors.filter((error) => error.type === ErrorType.OVERCHARGED);
	const underchargedErrors = errors.filter((error) => error.type === ErrorType.UNDERCHARGED);
	const misidentifiedErrors = errors.filter((error) => error.type === ErrorType.MISIDENTIFIED);

	const [snack, setSnack] = useState<{ message: string; severity: 'success' | 'error' | 'info' | 'warning' } | null>(null);

	const canReviewBeAdded =
		errors.filter((error) => error.type === ErrorType.OVERCHARGED).length > 0 && errors.filter((error) => error.type === ErrorType.UNDERCHARGED).length > 0;

	const submitReview = async () => {
		try {
			setSnack({ message: 'Adding Review...', severity: 'info' });
			if (submitPossible) {
				await API.addAccuracyJourneyReview(Number(params.id), manualReviews);
				setSubmitPossible(false);
			}
			if (scannedItemsEdited) {
				await API.updateAccuracyJourneyScannedItems(Number(params.id), manualScannedItems);
				setScannedItemsEdited(false);
			}
			setSnack({ message: 'Review added', severity: 'success' });
			navigate(-1);
		} catch (e) {
			setSnack({ message: 'Review could not be added', severity: 'error' });
			console.log(e);
		} finally {
			setTimeout(() => {
				setSnack(null);
			}, 5000);
		}
	};

	const invalidateJourney = async () => {
		const alertResult = window.confirm('Are you sure you want to invalidate this journey? This action cannot be undone.');
		if (!alertResult) return;

		try {
			setSnack({ message: 'Invalidating Journey...', severity: 'info' });
			await API.invalidateAccuracyJourney(Number(params.id));
			setSnack({ message: 'Journey invalidated', severity: 'success' });
			setSubmitPossible(false);
			navigate(-1);
		} catch (e) {
			setSnack({ message: 'Journey could not be invalidated', severity: 'error' });
			console.log(e);
		} finally {
			setTimeout(() => {
				setSnack(null);
			}, 5000);
		}
	};

	const startReviewProcess = () => {
		setReviewDialogOpen(true);
		setReviewDialogState('aifi');
	};

	const addReview = (review: AccuracyTestingManualReview) => {
		setManualReviews([...manualReviews, review]);
		setSubmitPossible(true);
	};

	const deleteReview = (review: AccuracyTestingManualReview) => {
		setManualReviews(manualReviews.filter((r) => r !== review));
		setSubmitPossible(true);
	};

	const selectAiFiItem = (item: CartItemWithProductCode) => {
		setSelectedAifiItem(item);
		setReviewDialogState('scanned');
	};

	const selectScannedItem = (item: CartItemWithProductCode) => {
		setSelectedScannedItem(item);
		setReviewDialogState('type');
	};

	const selectReviewType = (type: AccuracyTestingManualReviewType) => {
		setReviewDialogOpen(false);
		addReview({
			aifiProductIdentifier: getUniqueItemIdentifier(selectedAifiItem)!,
			scanProductIdentifier: getUniqueItemIdentifier(selectedScannedItem)!,
			type: type,
		});
	};

	const addAiFiItemToScannedItems = (item: CartItemWithProductCode) => {
		setManualScannedItems([...manualScannedItems, item]);
		setAddScannedItemDialogOpen(false);
		setScannedItemsEdited(true);
	};

	const increaseScannedQuantity = (item: CartItemWithProductCode) => {
		const newScannedItems = manualScannedItems.map((i) => {
			if (getUniqueItemIdentifier(i) === getUniqueItemIdentifier(item)) {
				return {
					...i,
					amount: i.amount + 1,
				};
			}
			return i;
		});
		setManualScannedItems(newScannedItems);
		setScannedItemsEdited(true);
	};

	const decreaseScannedQuantity = (item: CartItemWithProductCode) => {
		const newScannedItems = manualScannedItems.map((i) => {
			if (getUniqueItemIdentifier(i) === getUniqueItemIdentifier(item)) {
				return {
					...i,
					amount: i.amount - 1,
				};
			}
			return i;
		});
		setManualScannedItems(newScannedItems);
		setScannedItemsEdited(true);
	};

	const removeScannedItem = (item: CartItemWithProductCode) => {
		const newScannedItems = manualScannedItems.filter((i) => {
			return getUniqueItemIdentifier(i) !== getUniqueItemIdentifier(item);
		});
		setManualScannedItems(newScannedItems);
		setScannedItemsEdited(true);
	};

	useEffect(() => {
		if (!params.id) return;
		(async () => {
			const response = await API.getAccuracyJourneyDetails(Number(params.id));
			setJourney(response);
			setManualScannedItems(response.scannedItems);
			setManualReviews(response.manualReviews);
			setSubmitPossible(!journey?.reviewed);
		})();
	}, [params.id]);

	useEffect(() => {
		const errors: Array<{
			type: ErrorType;
			overchargedProduct: CartItemWithProductCode | undefined;
			underchargedProduct: CartItemWithProductCode | undefined;
			errorCount: number;
			message: string;
		}> = [];

		if (!journey) return;
		if (!journey.aifiItems) return;
		if (!manualScannedItems) return;

		let count = 0;

		const aifiItems = journey.aifiItems;
		const scannedItems = manualScannedItems.map((item) => {
			const review = manualReviews?.find((review) => review.scanProductIdentifier === getUniqueItemIdentifier(item));
			if (review && review.type === AccuracyTestingManualReviewType.MASTER_DATA_CONFLICT) {
				const aifiItem = aifiItems.find((aifiItem) => getUniqueItemIdentifier(aifiItem) === review.aifiProductIdentifier);
				if (!aifiItem) return item;
				return {
					...item,
					productCode: aifiItem.productCode,
					overrideUniqueProductIdentifier: getUniqueItemIdentifier(item),
				};
			}
			return item;
		});

		const productCodes = new Set<string>();
		for (const item of aifiItems) {
			productCodes.add(item.productCode);
		}
		for (const item of scannedItems) {
			productCodes.add(item.productCode);
		}

		const productCodesArray = Array.from(productCodes);

		if (manualReviews) {
			for (const review of manualReviews) {
				if (review.type === AccuracyTestingManualReviewType.MISIDENTIFIED_ITEM) {
					const aifiItem = aifiItems.find((item) => getUniqueItemIdentifier(item) === review.aifiProductIdentifier);
					const scannedItem = scannedItems.find((item) => getUniqueItemIdentifier(item) === review.scanProductIdentifier);

					const aifiAmount = aifiItem ? aifiItem.amount : 0;
					const scannedAmount = scannedItem ? scannedItem.amount : 0;

					const diff = Math.abs(aifiAmount - scannedAmount);

					const min = Math.min(aifiAmount, scannedAmount);

					count += diff + min;
					errors.push({
						type: ErrorType.MISIDENTIFIED,
						overchargedProduct: aifiItem,
						underchargedProduct: scannedItem,
						errorCount: diff + min,
						message: `${scannedItem?.name} was misidentified as ${aifiItem?.name} - counting as ${diff + min} error${diff + min === 1 ? '' : 's'}`,
					});
				}
			}
		}

		for (const productCode of productCodesArray) {
			const aifiItemsWithProductCode = aifiItems.filter((item) => item.productCode === productCode);
			const scannedItemsWithProductCode = scannedItems.filter((item) => item.productCode === productCode);

			const aifiItem =
				aifiItemsWithProductCode.length > 0
					? aifiItemsWithProductCode.reduce((prev, current) => {
							return {
								...prev,
								amount: prev.amount + current.amount,
							};
					  })
					: undefined;

			const scannedItem =
				scannedItemsWithProductCode.length > 0
					? scannedItemsWithProductCode.reduce((prev, current) => {
							return {
								...prev,
								amount: prev.amount + current.amount,
							};
					  })
					: undefined;

			const aifiAmount = aifiItem ? aifiItem.amount : 0;
			const scannedAmount = scannedItem ? scannedItem.amount : 0;

			const review = manualReviews?.find(
				(review) =>
					review.aifiProductIdentifier === getUniqueItemIdentifier(aifiItem) || review.scanProductIdentifier === getUniqueItemIdentifier(scannedItem)
			);

			if (review && review.type === AccuracyTestingManualReviewType.MISIDENTIFIED_ITEM) {
				continue;
			}

			if (aifiAmount > scannedAmount) {
				count += aifiAmount - scannedAmount;

				errors.push({
					type: ErrorType.OVERCHARGED,
					overchargedProduct: aifiItem,
					underchargedProduct: scannedItem,
					errorCount: aifiAmount - scannedAmount,
					message: `${aifiItem?.name} was overcharged ${aifiAmount - scannedAmount} time${
						aifiAmount - scannedAmount === 1 ? '' : 's'
					} - counting as ${aifiAmount - scannedAmount} error${aifiAmount - scannedAmount === 1 ? '' : 's'}`,
				});
			} else if (aifiAmount < scannedAmount) {
				count += scannedAmount - aifiAmount;
				errors.push({
					type: ErrorType.UNDERCHARGED,
					overchargedProduct: aifiItem,
					underchargedProduct: scannedItem,
					errorCount: scannedAmount - aifiAmount,
					message: `${scannedItem?.name} was undercharged ${scannedAmount - aifiAmount} time${
						scannedAmount - aifiAmount === 1 ? '' : 's'
					} - counting as ${scannedAmount - aifiAmount} error${scannedAmount - aifiAmount === 1 ? '' : 's'}`,
				});
			}
		}

		setErrorCount(Math.min(count, journey.itemCount));
		setErrors(errors);
	}, [journey, manualReviews, manualScannedItems]);

	return (
		<Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
			{snack ? (
				<Snackbar open={true} autoHideDuration={1000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
					<Alert severity={snack.severity} sx={{ width: '100%' }}>
						{snack.message}
					</Alert>
				</Snackbar>
			) : null}
			<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
				<Typography variant='h4' component='h1' gutterBottom>
					Testing Journey
				</Typography>
				<Box sx={{ display: 'flex', alignItems: 'space-between' }}>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Button variant='outlined' color='error' startIcon={<Delete />} onClick={invalidateJourney} sx={{ mr: 1 }}>
							Invalidate Journey
						</Button>
						{journey?.referenceOrderCustomerId != null && (
							<Button
								variant='outlined'
								color='primary'
								startIcon={<ReceiptLong />}
								target='_blank'
								href={`/journey?customerId=${journey?.referenceOrderCustomerId}`}
								sx={{ mr: 1 }}
							>
								View Target Receipt
							</Button>
						)}
						{journey?.aifiOrderId != null && (
							<Button
								variant='outlined'
								color='primary'
								sx={{ mr: 1 }}
								startIcon={<Videocam />}
								onClick={() => {
									window.open(`${API.config.aifiBaseUrl}/${journey.aifiOrderId}`);
								}}
							>
								View Order {journey.aifiOrderId} in AiFi Console
							</Button>
						)}
						{(submitPossible || scannedItemsEdited) && (
							<Button variant='contained' color='primary' startIcon={<CloudUpload />} onClick={submitReview} sx={{ mr: 1 }}>
								Submit Review
							</Button>
						)}
					</Box>
				</Box>
			</Box>
			<Divider sx={{ mb: 4 }} />
			<Grid container spacing={3}>
				<Grid item xs={12} md={12} lg={12}>
					<Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
						<Typography color='text.secondary' gutterBottom>
							Details
						</Typography>
						<Typography variant='body2' gutterBottom>
							Journey Customer ID: {journey?.customerId}
						</Typography>
						<Typography variant='body2' gutterBottom>
							AiFi Session ID: {journey?.aifiSessionId}
						</Typography>
						<Typography variant='body2' gutterBottom>
							Accuracy: {journey && journey.itemCount > 0 ? Math.floor(((journey.itemCount - errorCount) / journey.itemCount) * 100) : 100}%
						</Typography>
					</Paper>
				</Grid>
				{(manualReviews.length > 0 || canReviewBeAdded) && (
					<Grid item xs={12} md={12} lg={12}>
						<Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
							<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
								<Typography color='text.secondary'>Reviews</Typography>
								{canReviewBeAdded && (
									<Button variant='outlined' color='primary' startIcon={<Add />} onClick={startReviewProcess}>
										Add Review
									</Button>
								)}
							</Box>
							{manualReviews.map((review: AccuracyTestingManualReview) => {
								const aifiItem = journey?.aifiItems.find((item) => getUniqueItemIdentifier(item) === review.aifiProductIdentifier);
								const scannedItem = manualScannedItems.find((item) => getUniqueItemIdentifier(item) === review.scanProductIdentifier);
								if (!aifiItem || !scannedItem) return null;
								return (
									<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
										<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
											<Typography variant='body2'>
												{review.type === AccuracyTestingManualReviewType.MASTER_DATA_CONFLICT
													? 'Master Data Conflict'
													: 'Misidentified Item'}
												:
											</Typography>
											<Typography variant='body2'>
												{aifiItem.name} ({aifiItem.code} / {aifiItem.productCode}) - {scannedItem.name} ({scannedItem.code} /{' '}
												{scannedItem.productCode})
											</Typography>
										</Box>
										<Button
											variant='text'
											color='primary'
											endIcon={<Delete />}
											onClick={() => {
												deleteReview(review);
											}}
										>
											Delete Review
										</Button>
									</Box>
								);
							})}
						</Paper>
					</Grid>
				)}
				{overchargedErrors.length > 0 && (
					<Grid item xs={12} md={12} lg={6}>
						<Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
							<Typography color='text.secondary' gutterBottom>
								Errors - Overcharged
							</Typography>
							{overchargedErrors.map((error) => (
								<Typography variant='body2' gutterBottom>
									{error.message}
								</Typography>
							))}
						</Paper>
					</Grid>
				)}
				{underchargedErrors.length > 0 && (
					<Grid item xs={12} md={12} lg={6}>
						<Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
							<Typography color='text.secondary' gutterBottom>
								Errors - Undercharged
							</Typography>
							{underchargedErrors.map((error) => (
								<Typography variant='body2' gutterBottom>
									{error.message}
								</Typography>
							))}
						</Paper>
					</Grid>
				)}
				{misidentifiedErrors.length > 0 && (
					<Grid item xs={12} md={12} lg={12}>
						<Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
							<Typography color='text.secondary' gutterBottom>
								Errors - Misidentified
							</Typography>
							{misidentifiedErrors.map((error) => (
								<Typography variant='body2' gutterBottom>
									{error.message}
								</Typography>
							))}
						</Paper>
					</Grid>
				)}
			</Grid>
			<Grid container spacing={3} sx={{ mt: 1 }}>
				<Grid item xs={12} md={6} lg={6}>
					<Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
						<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', mb: 1, height: '40px' }}>
							<Typography color='text.secondary' sx={{ marginTop: 'auto', marginBottom: 'auto' }}>
								AiFi Receipt
							</Typography>
						</Box>
						{journey?.aifiItems
							?.sort((a, b) => {
								return Number(a.productCode) > Number(b.productCode) ? 1 : -1;
							})
							.map((item: CartItemWithProductCode) => (
								<ReceiptItem item={item} key={`${item.code}-${item.amount}`} />
							))}
					</Paper>
				</Grid>
				<Grid item xs={12} md={6} lg={6}>
					<Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
						<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', mb: 1, height: '40px' }}>
							<Typography color='text.secondary' sx={{ marginTop: 'auto', marginBottom: 'auto' }}>
								Scanned Receipt
							</Typography>
							<Button variant='outlined' color='primary' startIcon={<Add />} onClick={() => setAddScannedItemDialogOpen(true)}>
								Add Item
							</Button>
						</Box>
						{manualScannedItems
							?.sort((a, b) => {
								return Number(a.productCode) > Number(b.productCode) ? 1 : -1;
							})
							.map((item: CartItemWithProductCode) => (
								<ReceiptItem
									item={item}
									key={`${item.code}-${item.productCode}`}
									addButtonEnabled={true}
									removeButtonEnabled={item.amount > 1}
									deleteButtonEnabled={item.amount === 1}
									onAdd={() => increaseScannedQuantity(item)}
									onRemove={() => decreaseScannedQuantity(item)}
									onDelete={() => removeScannedItem(item)}
								/>
							))}
					</Paper>
				</Grid>
			</Grid>
			<Dialog open={reviewDialogOpen} onClose={() => setReviewDialogOpen(false)}>
				<DialogTitle>Add Review</DialogTitle>
				<DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
					<DialogContentText>
						{reviewDialogState === 'aifi' && 'Select the AiFi item'}
						{reviewDialogState === 'scanned' && 'Select the scanned item'}
						{reviewDialogState === 'type' && 'Select the review type'}
					</DialogContentText>
					{reviewDialogState === 'aifi' &&
						errors
							.filter((error) => error.type === ErrorType.OVERCHARGED)
							.map((item) => {
								return (
									<Button
										onClick={() => {
											selectAiFiItem(item.overchargedProduct!);
										}}
									>
										{item.overchargedProduct!.name} ({item.overchargedProduct!.code} / {item.overchargedProduct!.productCode})
									</Button>
								);
							})}
					{reviewDialogState === 'scanned' &&
						errors
							.filter((error) => error.type === ErrorType.UNDERCHARGED)
							.map((item) => {
								return (
									<Button
										onClick={() => {
											selectScannedItem(item.underchargedProduct!);
										}}
									>
										{item.underchargedProduct!.name} ({item.underchargedProduct!.code} / {item.underchargedProduct!.productCode})
									</Button>
								);
							})}
					{reviewDialogState === 'type' && (
						<>
							<Button
								onClick={() => {
									selectReviewType(AccuracyTestingManualReviewType.MASTER_DATA_CONFLICT);
								}}
							>
								Master Data Conflict
							</Button>
							<Button
								onClick={() => {
									selectReviewType(AccuracyTestingManualReviewType.MISIDENTIFIED_ITEM);
								}}
							>
								Misidentified Item
							</Button>
						</>
					)}
				</DialogContent>
			</Dialog>
			<Dialog open={addScannedItemDialogOpen} onClose={() => setAddScannedItemDialogOpen(false)}>
				<DialogTitle>Add AiFi Item to Scanned Items</DialogTitle>
				<DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
					<DialogContentText>Select the AiFi item</DialogContentText>
					{errors
						.filter((error) => error.type === ErrorType.OVERCHARGED)
						.map((item) => {
							return (
								<Button
									onClick={() => {
										addAiFiItemToScannedItems(item.overchargedProduct!);
									}}
								>
									{item.overchargedProduct!.name} ({item.overchargedProduct!.code} / {item.overchargedProduct!.productCode})
								</Button>
							);
						})}
				</DialogContent>
			</Dialog>
		</Container>
	);
};

export default AccuracyDetailPage;

export default interface AlertOccurrence {
	_id: string;
	alertId: string;
	status: AlertStatus;
	severity: AlertSeverity;
	name: string;
	description: string;
	notificationId: string | null;
	acknowledgedBy: string | null;
	startTimestamp: Date;
	acknowledgedTimestamp: Date | null;
	endTimestamp: Date | null;
}

export enum AlertStatus {
	FIRING = 'FIRING',
	WORKING = 'WORKING',
	RESOLVED = 'RESOLVED',
}

export enum AlertSeverity {
	CRITICAL = 'CRITICAL',
	NORMAL = 'NORMAL',
}

import React from 'react';
import Container from '@mui/material/Container';
import { Box, Button, Paper } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import Title from '../General/Title';
import { Download } from '@mui/icons-material';

const IssueReportDownloadSelector = () => {
	const startOfLastMonth = DateTime.local().startOf('month').minus({ months: 1 });
	const tomorrow = DateTime.local().plus({ days: 1 });

	const [startDate, setStartDate] = React.useState(startOfLastMonth);
	const [endDate, setEndDate] = React.useState(tomorrow);

	return (
		<Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
			<Paper
				sx={{
					display: 'flex',
					flexDirection: 'column',
					mb: 4,
				}}
			>
				<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, pl: 2, pr: 2, pb: 2 }}>
					<Title>Issue Report Download</Title>
				</Box>
				<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, pl: 2, pr: 2, pb: 2 }}>
					<DatePicker
						label='Start Date'
						value={startDate}
						sx={{ mr: 2 }}
						onChange={(newValue) => {
							//@ts-ignore
							setStartDate(newValue);
						}}
					/>
					<DatePicker
						label='End Date'
						value={endDate}
						sx={{ mr: 2 }}
						onChange={(newValue) => {
							//@ts-ignore
							setEndDate(newValue);
						}}
					/>

					<Button
						variant='contained'
						startIcon={<Download />}
						target='_blank'
						href={`/staffApp/issue/report?startDate=${startDate.toJSDate().toISOString().substring(0, 10)}&endDate=${endDate
							.toJSDate()
							.toISOString()
							.substring(0, 10)}`}
						sx={{ flex: 1 }}
					>
						Download Logged Issues
					</Button>
				</Box>
			</Paper>
		</Container>
	);
};

export default IssueReportDownloadSelector;

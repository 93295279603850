import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Box, Button } from '@mui/material';
import { RadioButtonChecked, Replay } from '@mui/icons-material';
import { Color } from '../../assets/Color';
import Title from './Title';
import { useAppSelector } from '../../datalayer/hooks';

interface TitleProps {
	children?: React.ReactNode;
	sseRelevant: boolean;
	refresh: () => void;
	lastRefresh: Date;
	centerComponent?: React.ReactNode;
}

export default function TitleLive(props: TitleProps) {
	const sseConnected = useAppSelector((store) => store.status.sseConnected);

	return (
		<Box sx={{ display: 'flex', flexDirection: 'row' }}>
			<Title>{props.children}</Title>
			{props.centerComponent}
			{sseConnected && props.sseRelevant ? null : (
				<>
					<Button size='medium' onClick={props.refresh} sx={{ ml: 'auto' }}>
						<Replay color='primary' />
					</Button>
					<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
						<Typography sx={{ mb: 'auto', mt: 'auto', fontSize: 12 }}>Updated at</Typography>
						<Typography sx={{ mb: 'auto', mt: 'auto', fontSize: 12 }}>{props.lastRefresh.toLocaleTimeString()}</Typography>
					</Box>
				</>
			)}
		</Box>
	);
}

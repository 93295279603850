import React, { useEffect } from 'react';
import Container from '@mui/material/Container';
import { Box, Button, Paper, TableCell, TablePagination, TableRow, Typography } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import API from '../datalayer/API';
import CheckinEvent from '../models/CheckinEvent';
import { EventType } from '../models/EventType';
import EventItem from '../components/General/EventItem';
import AiFiEvent from '../models/AiFiEvent';
import ScanEvent from '../models/ScanEvent';
import eventEmitter from '../datalayer/EventEmitter';
import TapAppInEvent from '../models/TapAppInEvent';
import ScrollEventEmitter from '../datalayer/ScrollEventEmitter';

const CheckinPage = () => {
	const [loading, setLoading] = React.useState(false);
	const [hasMore, setHasMore] = React.useState(true);

	const [checkinEvents, setCheckinEvents] = React.useState<Array<CheckinEvent | TapAppInEvent>>([]);

	const fetchEvents = async ({ limit, lastId, reset }: { limit: number; lastId?: string; reset?: boolean }) => {
		setLoading(true);
		API.getEvents({ amount: 20, lastId, types: [EventType.CHECKIN, EventType.APP_IN, EventType.TAP_IN] })
			.then((data) => {
				setHasMore(data.length === limit);
				if (reset) {
					setCheckinEvents(data as Array<CheckinEvent | TapAppInEvent>);
				} else {
					setCheckinEvents([...checkinEvents, ...data] as Array<CheckinEvent | TapAppInEvent>);
				}
				setLoading(false);
			})
			.catch((e) => {
				console.error(e);
				setLoading(false);
			});
	};

	const handleNewEvent = (event: AiFiEvent | ScanEvent | CheckinEvent | TapAppInEvent) => {
		if (![EventType.CHECKIN, EventType.APP_IN, EventType.TAP_IN].includes(event.type)) return;
		setCheckinEvents((events) => {
			return [event as CheckinEvent | TapAppInEvent, ...events];
		});
	};

	useEffect(() => {
		//todo error handling
		fetchEvents({ limit: 20, reset: true });

		eventEmitter.addListener('storeEvent', handleNewEvent);

		return () => {
			eventEmitter.removeListener('storeEvent', handleNewEvent);
		};
	}, []);

	useEffect(() => {
		const bottomOfPageReached = () => {
			if (hasMore && !loading) {
				const lastId = checkinEvents.at(-1)?._id;
				fetchEvents({ limit: 20, lastId });
			}
		};
		ScrollEventEmitter.on('bottom', bottomOfPageReached);
		return () => {
			ScrollEventEmitter.off('bottom', bottomOfPageReached);
		};
	}, [checkinEvents, hasMore, loading]);

	return (
		<Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
			<Paper sx={{ width: '100%', mb: 2, p: 2 }}>
				<Box sx={{ display: 'flex', flexDirection: 'row' }}>
					<Typography variant='h6'>Check-In Events</Typography>
				</Box>
				{checkinEvents.map((event, index) => {
					return <EventItem event={event} key={event.timestamp + event.type} displayButton={true} displayFullDate={true} />;
				})}
				{loading ? (
					<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', pt: 2 }}>
						<Typography variant='body2' sx={{ ml: 'auto', mr: 'auto' }}>
							Loading...
						</Typography>
					</Box>
				) : null}
				{!hasMore ? (
					<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', pt: 2 }}>
						<Typography variant='body2' sx={{ ml: 'auto', mr: 'auto' }}>
							{checkinEvents.length === 0 ? 'No events found' : 'You have reached the end of the list'}
						</Typography>
					</Box>
				) : null}
			</Paper>
		</Container>
	);
};

export default CheckinPage;

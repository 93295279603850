import { Button, Grid, Paper, Typography } from '@mui/material';
import DashboardJourney, { CustomerJourneyState } from '../../models/DashboardJourney';
import { DoorSlidingOutlined, Liquor, PersonAddAlt1, PersonRemove, QrCodeScanner, ShoppingCart } from '@mui/icons-material';
import { SimulatedEventType } from '../../models/SimulatedEventType';
import API from '../../datalayer/API';
import { useState } from 'react';
import { useAppSelector } from '../../datalayer/hooks';
import { AiFiProduct } from '../../models/AiFiProduct';

const JourneyEventSimulation = ({
	journey,
	reload,
	liveReceipt,
}: {
	journey: DashboardJourney;
	reload: () => Promise<void>;
	liveReceipt: Array<AiFiProduct>;
}) => {
	const [loading, setLoading] = useState(false);
	const sseAllowed = useAppSelector((store) => store.status.sseAllowed);

	const simulateEvent = async (eventType: SimulatedEventType) => {
		try {
			setLoading(true);
			await API.simulateJourneyEvent(journey.customerId, eventType);
			await new Promise((resolve) => setTimeout(resolve, 1000));
			if (!sseAllowed) {
				await reload();
			}
			setLoading(false);
		} catch (error) {
			console.error(error);
			setLoading(false);
		}
	};

	return (
		<Grid item xs={12} md={6} lg={6}>
			<Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
				<Typography color='text.secondary' gutterBottom sx={{ pb: 2 }}>
					Journey Simulation
				</Typography>
				{loading ? (
					<Typography color='text.secondary' gutterBottom sx={{ pb: 2 }}>
						Loading...
					</Typography>
				) : (
					<Grid container spacing={3}>
						{journey.entryGateId == null && (
							<Grid item xs={12} md={12} lg={12} sx={{ display: 'flex' }}>
								<Button
									variant='outlined'
									color='primary'
									startIcon={<QrCodeScanner />}
									onClick={() => simulateEvent(SimulatedEventType.ENTRY_SCAN)}
								>
									Entry Scan
								</Button>
							</Grid>
						)}
						{journey.entryGateId != null &&
							(journey.state === CustomerJourneyState.ANNOUNCEMENT || journey.state === CustomerJourneyState.CHECKIN) && (
								<Grid item xs={12} md={12} lg={12} sx={{ display: 'flex' }}>
									<Button
										variant='outlined'
										color='primary'
										startIcon={<PersonAddAlt1 />}
										onClick={() => simulateEvent(SimulatedEventType.AIFI_ENTERED)}
									>
										AiFi Entered Event
									</Button>
								</Grid>
							)}
						{journey.state === CustomerJourneyState.CHECKIN && journey.currentCustomerAmount > 0 && (
							<>
								<Grid item xs={12} md={12} lg={12} sx={{ display: 'flex' }}>
									<Button
										variant='outlined'
										color='primary'
										startIcon={<DoorSlidingOutlined />}
										onClick={() => simulateEvent(SimulatedEventType.AIFI_CHECKOUT_ZONE_ENTERED)}
									>
										AiFi Checkout Zone Entered Event
									</Button>
								</Grid>
								<Grid item xs={12} md={12} lg={12} sx={{ display: 'flex' }}>
									<Button
										variant='outlined'
										color='primary'
										startIcon={<Liquor />}
										onClick={() => simulateEvent(SimulatedEventType.AIFI_CHECKOUT_ZONE_ENTERED_WITH_RESTRICTED_ITEMS)}
									>
										AiFi Checkout Zone Entered Event with restricted items
									</Button>
								</Grid>
								<Grid item xs={12} md={12} lg={12} sx={{ display: 'flex' }}>
									<Button
										variant='outlined'
										color='primary'
										startIcon={<DoorSlidingOutlined />}
										onClick={() => simulateEvent(SimulatedEventType.AIFI_CHECKOUT_ZONE_LEFT)}
									>
										AiFi Checkout Zone Left Event
									</Button>
								</Grid>
								<Grid item xs={12} md={12} lg={12} sx={{ display: 'flex' }}>
									<Button
										variant='outlined'
										color='primary'
										startIcon={<PersonRemove />}
										onClick={() => simulateEvent(SimulatedEventType.AIFI_LEFT)}
									>
										AiFi Left Event
									</Button>
								</Grid>
							</>
						)}
						{(journey.state === CustomerJourneyState.LEFT || journey.state === CustomerJourneyState.COMPLETED) && (
							<>
								{journey.state !== CustomerJourneyState.COMPLETED && liveReceipt.length > 0 && (
									<Grid item xs={12} md={12} lg={12} sx={{ display: 'flex' }}>
										<Button
											variant='outlined'
											color='primary'
											startIcon={<ShoppingCart />}
											onClick={() => simulateEvent(SimulatedEventType.AIFI_CHECKOUT_FROM_LIVE_EVENT)}
										>
											Accept Live Receipt
										</Button>
									</Grid>
								)}
								<Grid item xs={12} md={12} lg={12} sx={{ display: 'flex' }}>
									<Button
										variant='outlined'
										color='primary'
										startIcon={<ShoppingCart />}
										onClick={() => simulateEvent(SimulatedEventType.AIFI_CHECKOUT_EMPTY)}
									>
										AiFi Checkout Event Empty {journey.state === CustomerJourneyState.COMPLETED && '(re-send)'}
									</Button>
								</Grid>
								<Grid item xs={12} md={12} lg={12} sx={{ display: 'flex' }}>
									<Button
										variant='outlined'
										color='primary'
										startIcon={<ShoppingCart />}
										onClick={() => simulateEvent(SimulatedEventType.AIFI_CHECKOUT_S)}
									>
										AiFi Checkout Event S {journey.state === CustomerJourneyState.COMPLETED && '(re-send)'}
									</Button>
								</Grid>
								<Grid item xs={12} md={12} lg={12} sx={{ display: 'flex' }}>
									<Button
										variant='outlined'
										color='primary'
										startIcon={<ShoppingCart />}
										onClick={() => simulateEvent(SimulatedEventType.AIFI_CHECKOUT_M)}
									>
										AiFi Checkout Event M {journey.state === CustomerJourneyState.COMPLETED && '(re-send)'}
									</Button>
								</Grid>
								<Grid item xs={12} md={12} lg={12} sx={{ display: 'flex' }}>
									<Button
										variant='outlined'
										color='primary'
										startIcon={<ShoppingCart />}
										onClick={() => simulateEvent(SimulatedEventType.AIFI_CHECKOUT_L)}
									>
										AiFi Checkout Event L {journey.state === CustomerJourneyState.COMPLETED && '(re-send)'}
									</Button>
								</Grid>
							</>
						)}
					</Grid>
				)}
			</Paper>
		</Grid>
	);
};

export default JourneyEventSimulation;

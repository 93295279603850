import { Contactless, HourglassBottom, QrCodeScanner, ReceiptLong, Store } from '@mui/icons-material';
import CustomProgressBar from './CustomProgressBar';
import { CustomerJourneyState, JourneyType } from '../../models/DashboardJourney';

const JourneyProgressBar = ({ activeState, stateMillis, type }: { activeState: CustomerJourneyState; type: JourneyType; stateMillis: any }) => {
	const states = [
		{
			state: CustomerJourneyState.ANNOUNCEMENT,
			label: type === JourneyType.APP_IN ? 'QR code' : 'Card',
			sublabel: type === JourneyType.APP_IN ? 'generated at' : 'tapped at',
			icon: type === JourneyType.APP_IN ? QrCodeScanner : Contactless,
		},
		{
			state: CustomerJourneyState.CHECKIN,
			label: 'Shopping',
			sublabel: 'entered the store at',
			icon: Store,
		},
		{
			state: CustomerJourneyState.LEFT,
			label: 'Waiting for their receipt',
			sublabel: 'left the store at',
			icon: HourglassBottom,
		},
		{
			state: CustomerJourneyState.COMPLETED,
			label: 'Basket from AiFi',
			sublabel: 'received at',
			icon: ReceiptLong,
		},
	];

	return <CustomProgressBar activeState={activeState} stateMillis={stateMillis} states={states} />;
};

export default JourneyProgressBar;

import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import Container from '@mui/material/Container';
import API from '../datalayer/API';
import { Button, Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import AlertOccurrence, { AlertSeverity } from '../models/AlertOccurrence';
import ScrollEventEmitter from '../datalayer/ScrollEventEmitter';
import { Report, Warning } from '@mui/icons-material';
import { RoleGate } from '../components/General/RoleGate';
import { StaffRole } from '../models/StaffRole';

const SystemHealthPage = () => {
	const [checkoutBlocked, setCheckoutBlocked] = useState(false);
	const [sprykerCustomerCheckBlocked, setSprykerCustomerCheckBlocked] = useState(false);

	const [activeAlerts, setActiveAlerts] = useState<Array<AlertOccurrence>>([]);
	const [alertHistory, setAlertHistory] = useState<Array<AlertOccurrence>>([]);
	const [hasMoreAlerts, setHasMoreAlerts] = useState(true);
	const [loading, setLoading] = useState(false);

	const refreshCheckoutBlocked = async () => {
		setCheckoutBlocked(await API.isCheckoutBlocked());
	};

	const refreshSprykerCustomerCheckBlocked = async () => {
		setSprykerCustomerCheckBlocked(await API.isSprykerCustomerCheckBlocked());
	};

	const triggerCheckoutBlocked = async () => {
		if (!checkoutBlocked) {
			const confirmed = window.confirm('Are you sure you want to block AiFi Checkout Event Processing?');
			if (!confirmed) return;
		}
		await API.setCheckoutBlocked(!checkoutBlocked);
		refreshCheckoutBlocked();
	};

	const triggerSprykerCustomerCheckBlocked = async () => {
		if (!sprykerCustomerCheckBlocked) {
			const confirmed = window.confirm('Are you sure you want to block Spryker Customer Check?');
			if (!confirmed) return;
		}
		await API.setSprykerCustomerCheckBlocked(!sprykerCustomerCheckBlocked);
		refreshSprykerCustomerCheckBlocked();
	};

	const fetchActiveAlerts = async () => {
		const alerts = await API.getFiringAlerts();
		setActiveAlerts(alerts);
	};

	const fetchAlertHistory = async ({ limit, lastId }: { limit?: number; lastId?: string }) => {
		setLoading(true);
		const alerts = await API.getAlertHistory({ limit, lastId });
		if (limit && alerts.length < limit) {
			setHasMoreAlerts(false);
		}
		setAlertHistory([...alertHistory, ...alerts]);
		setLoading(false);
	};

	useEffect(() => {
		refreshCheckoutBlocked();
		refreshSprykerCustomerCheckBlocked();
		fetchActiveAlerts();
		fetchAlertHistory({ limit: 10 });
	}, []);

	useEffect(() => {
		const bottomOfPageReached = () => {
			if (hasMoreAlerts && !loading) {
				const lastId = alertHistory.at(-1)?._id;
				fetchAlertHistory({ limit: 15, lastId });
			}
		};
		ScrollEventEmitter.on('bottom', bottomOfPageReached);
		return () => {
			ScrollEventEmitter.off('bottom', bottomOfPageReached);
		};
	}, [alertHistory, hasMoreAlerts, loading]);

	return (
		<Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
			{activeAlerts.length > 0 ? (
				<>
					<Typography variant='h6' sx={{ pt: 2, pl: 2, pr: 2, pb: 2 }}>
						Active Alerts
					</Typography>
					<Paper sx={{ width: '100%', mb: 2 }}>
						<TableContainer>
							<Table aria-label='simple table'>
								<TableBody>
									{activeAlerts.map((alert, index) => (
										<TableRow key={alert._id + index}>
											<TableCell align='center'>
												<Typography variant='body2' fontWeight={'bold'}>
													{alert.severity === AlertSeverity.CRITICAL ? <Report color={'error'} /> : <Warning color='warning' />}
												</Typography>
											</TableCell>
											<TableCell>
												<Typography variant='body2' fontWeight={'bold'}>
													{alert.name}
												</Typography>
												<Typography variant='body2'>{alert.description}</Typography>

												<Typography variant='body2' sx={{ mt: 1 }} fontWeight='500'>
													{alert.acknowledgedBy
														? `Support contact: ${alert.acknowledgedBy}`
														: alert.severity === AlertSeverity.CRITICAL
														? 'On-call support has been alerted...'
														: 'On-call support has not been alerted due to non-critical alert.'}
												</Typography>
											</TableCell>
											<TableCell align='center'>
												<Typography variant='body2'>triggered at</Typography>
												<Typography variant='body2' fontWeight={'bold'}>
													{`${new Date(alert.startTimestamp).toLocaleDateString()} ${new Date(
														alert.startTimestamp
													).toLocaleTimeString()}`}
												</Typography>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Paper>
				</>
			) : null}
			<RoleGate requiredRole={StaffRole.ROOT}>
				<>
					<Typography variant='h6' sx={{ pt: 2, pl: 2, pr: 2, pb: 2 }}>
						Support Actions
					</Typography>
					<Paper sx={{ width: '100%', mb: 2 }}>
						<Button onClick={triggerCheckoutBlocked} variant='contained' color={checkoutBlocked ? 'success' : 'error'} sx={{ m: 2 }}>
							{checkoutBlocked ? 'Restart AiFi Checkout Event Processing' : 'Block AiFi Checkout Event Processing'}
						</Button>
						<Button
							onClick={triggerSprykerCustomerCheckBlocked}
							variant='contained'
							color={sprykerCustomerCheckBlocked ? 'success' : 'error'}
							sx={{ m: 2 }}
						>
							{sprykerCustomerCheckBlocked ? 'Enable Spryker Customer Check' : 'Disable Spryker Customer Check'}
						</Button>
					</Paper>
				</>
			</RoleGate>
			<Typography variant='h6' sx={{ pt: 2, pl: 2, pr: 2, pb: 2 }}>
				Alert History {activeAlerts.length === 0 ? '(no active alerts)' : null}
			</Typography>
			<Paper sx={{ width: '100%', mb: 2 }}>
				<TableContainer>
					<Table aria-label='simple table'>
						<TableBody>
							{alertHistory.map((alert, index) => (
								<TableRow key={alert._id + index}>
									<TableCell align='center'>
										<Typography variant='body2' fontWeight={'bold'}>
											{alert.severity === AlertSeverity.CRITICAL ? <Report color='disabled' /> : <Warning color='disabled' />}
										</Typography>
									</TableCell>
									<TableCell>
										<Typography variant='body2' fontWeight={'bold'}>
											{alert.name}
										</Typography>
										<Typography variant='body2'>{alert.description}</Typography>
										{alert.acknowledgedBy ? (
											<Typography variant='body2' sx={{ mt: 1 }}>
												Handled by: {alert.acknowledgedBy}
											</Typography>
										) : null}
									</TableCell>
									<TableCell align='center'>
										<Typography variant='body2' fontWeight={'bold'}>
											{`${new Date(alert.startTimestamp).toLocaleDateString()} ${new Date(alert.startTimestamp).toLocaleTimeString()}`}
										</Typography>
										<Typography variant='body2'>-</Typography>
										<Typography variant='body2' fontWeight={'bold'}>
											{`${alert.endTimestamp ? new Date(alert.endTimestamp).toLocaleDateString() : 'ongoing'} ${
												alert.endTimestamp ? new Date(alert.endTimestamp).toLocaleTimeString() : ''
											}`}
										</Typography>
									</TableCell>
								</TableRow>
							))}
							{loading ? (
								<TableRow>
									<TableCell colSpan={3} align='center'>
										<Typography variant='body2'>Loading...</Typography>
									</TableCell>
								</TableRow>
							) : null}
							{!hasMoreAlerts ? (
								<TableRow>
									<TableCell align='center' colSpan={100}>
										<Typography variant='body2'>
											{alertHistory.length === 0 ? 'No alerts found' : 'You have reached the end of the list'}
										</Typography>
									</TableCell>
								</TableRow>
							) : null}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</Container>
	);
};

export default SystemHealthPage;
